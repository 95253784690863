import React, { CSSProperties, useEffect, useState } from "react";
import ClickAwayListener from "react-click-away-listener";
import { InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { ChromePicker, ColorResult } from "react-color";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  StyledColorBlock,
  StyledColorBlockContainer,
  StyledColorPickerContainer,
  StyledPickerContainer,
} from "./styles";
import { updateJobDescription } from "../../store_new/reducers/JobEditor";
import { getJobEditorState } from "../../store_new/selectors/JobEditor";
import { setIsColorPickerOpen } from "../../store_new/reducers/dialogs";

const ColorPicker = ({
  color,
  title,
  value,
  customStyle,
  hideColorText = false,
  isDefaultColor = false,
  customTextValue = "",
  onChange,
  onChangeName,
}: {
  readonly color: string;
  readonly title: string;
  readonly value?: string;
  readonly customStyle: CSSProperties;
  readonly hideColorText?: boolean;
  readonly isDefaultColor?: boolean;
  readonly customTextValue?: string;
  readonly onChange?: (data: string) => void;
  readonly onChangeName?: (value: string) => void;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isDisplayed, setIsDisplayed] = useState(false);
  const [currentColor, setCurrentColor] = useState(color);

  const { jobDescription } = useSelector(getJobEditorState);

  const handleOnChange = (color: ColorResult) => {
    setCurrentColor(color.hex);
    onChange && onChange(color.hex);
    if (value) {
      dispatch(
        updateJobDescription({
          jobDescription: {
            ...jobDescription,
            [value]: color.hex,
          },
        })
      );
    }
  };

  useEffect(() => {
    setCurrentColor(color);
  }, [color]);

  useEffect(() => {
    dispatch(setIsColorPickerOpen(isDisplayed));
  }, [isDisplayed]);

  return (
    <StyledColorPickerContainer direction={"row"} spacing={1}>
      {hideColorText && !isDefaultColor ? (
        <TextField
          className="workflow-color-textfield"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <StyledColorBlock
                  position="relative"
                  right={5}
                  bgColor={currentColor}
                  customTextValue={customTextValue}
                  onClick={() => {
                    if (!isDefaultColor) {
                      onChange && setIsDisplayed(true);
                    }
                  }}
                  isEditMode={Boolean(onChange)}
                ></StyledColorBlock>
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            if (onChangeName) {
              onChangeName(e.target.value);
            }
          }}
          defaultValue={customTextValue}
          placeholder={t("workflow.newState") as string}
          fullWidth
        />
      ) : (
        <StyledColorBlockContainer
          customTextValue={customTextValue}
          direction={"row"}
          spacing={0.75}
          flex={2}
        >
          <StyledColorBlock
            bgColor={currentColor}
            customTextValue={customTextValue}
            onClick={() => {
              if (!isDefaultColor) {
                onChange && setIsDisplayed(true);
              }
            }}
            isEditMode={Boolean(onChange)}
          ></StyledColorBlock>

          <Typography variant="subtitle2" color="textSecondary">
            {title}
          </Typography>
        </StyledColorBlockContainer>
      )}

      {!hideColorText && (
        <StyledColorBlockContainer flex={1}>
          <Typography variant="subtitle2" color="textSecondary">
            {currentColor}
          </Typography>
        </StyledColorBlockContainer>
      )}

      <StyledPickerContainer style={customStyle}>
        {isDisplayed && (
          <ClickAwayListener
            onClickAway={() => {
              setIsDisplayed(false);
            }}
          >
            <Stack>
              <ChromePicker color={currentColor} onChange={handleOnChange} />
            </Stack>
          </ClickAwayListener>
        )}
      </StyledPickerContainer>
    </StyledColorPickerContainer>
  );
};

export default ColorPicker;
