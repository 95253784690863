import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { DIALOG_TYPE, PayloadActionWithCallback } from "../../models/common";
import { IDialogsState } from "../../models/dialogs";

const initialState: IDialogsState = {
  isInviteMemberDialogOpen: false,
  isCompanyAddressDialogOpen: false,
  isLimitDialogOpen: false,
  isLimitConfirmationDialogOpen: false,
  isExtendLimitRequested: false,
  isDuplicateDialogOpen: false,
  duplicateJobUrlKey: undefined,
  isSelectCustomerDialogOpen: false,
  talentPoolsDialogType: null,
  isChangePasswordDialogOpen: false,
  isPasswordNotificationShowed: false,
  isUploadPictureDialogOpen: false,
  isArchiveRestoreModalOpen: false,
  isBuyProductDialogOpen: false,
  previewImage: null,
  currentWorkflowId: null,
  isWorkflowDialogOpen: false,
  isSelectWorkflowDialogOpen: false,
  isColorPickerOpen: false,
  columns: [],
};

export const DialogsSlice = createSlice({
  name: "DIALOGS",
  initialState,
  reducers: {
    setPreviewImage: (
      state: Draft<IDialogsState>,
      action: PayloadAction<File | null>
    ) => {
      state.previewImage = action.payload;
    },
    setIsInviteMemberDialogOpen: (
      state: Draft<IDialogsState>,
      action: PayloadAction<boolean>
    ) => {
      state.isInviteMemberDialogOpen = action.payload;
    },
    setIsDuplicateDialogOpen: (
      state: Draft<IDialogsState>,
      action: PayloadAction<boolean>
    ) => {
      state.isDuplicateDialogOpen = action.payload;
    },
    setIsBuyProductDialogOpen: (
      state: Draft<IDialogsState>,
      action: PayloadAction<boolean>
    ) => {
      state.isBuyProductDialogOpen = action.payload;
    },
    setIsSelectCustomerDialogOpen: (
      state: Draft<IDialogsState>,
      action: PayloadAction<boolean>
    ) => {
      state.isSelectCustomerDialogOpen = action.payload;
    },
    setIsChangePasswordDialogOpen: (
      state: Draft<IDialogsState>,
      action: PayloadAction<boolean>
    ) => {
      state.isChangePasswordDialogOpen = action.payload;
    },
    setDuplicateJobUrlKey: (
      state: Draft<IDialogsState>,
      action: PayloadAction<string>
    ) => {
      state.duplicateJobUrlKey = action.payload;
    },
    setIsCompanyAddressDialogOpen: (
      state: Draft<IDialogsState>,
      action: PayloadAction<boolean>
    ) => {
      state.isCompanyAddressDialogOpen = action.payload;
    },
    setIsLimitDialogOpen: (
      state: Draft<IDialogsState>,
      action: PayloadAction<boolean>
    ) => {
      state.isLimitDialogOpen = action.payload;
    },
    setIsTalentPoolsDialogOpen: (
      state: Draft<IDialogsState>,
      action: PayloadAction<DIALOG_TYPE | null>
    ) => {
      state.talentPoolsDialogType = action.payload;
    },
    setIsLimitConfirmationDialogOpen: (
      state: Draft<IDialogsState>,
      action: PayloadAction<boolean>
    ) => {
      state.isLimitConfirmationDialogOpen = action.payload;
    },
    setIsPasswordNotificationShowed: (
      state: Draft<IDialogsState>,
      action: PayloadAction<boolean>
    ) => {
      state.isPasswordNotificationShowed = action.payload;
    },
    setIsUploadPictureDialogOpen: (
      state: Draft<IDialogsState>,
      action: PayloadAction<boolean>
    ) => {
      state.isUploadPictureDialogOpen = action.payload;
    },
    setArchiveRestoreDialogOpen: (
      state: Draft<IDialogsState>,
      action: PayloadAction<boolean>
    ) => {
      state.isArchiveRestoreModalOpen = action.payload;
    },
    extendLimit: (
      state: Draft<IDialogsState>,
      _: PayloadActionWithCallback<unknown>
    ) => {
      state.isExtendLimitRequested = true;
    },
    extendLimitSuccess: (state: Draft<IDialogsState>) => {
      state.isExtendLimitRequested = false;
    },
    extendLimitFailed: (state: Draft<IDialogsState>) => {
      state.isExtendLimitRequested = false;
    },
    setCurrentWorkflowId: (
      state: Draft<IDialogsState>,
      action: PayloadAction<number | null>
    ) => {
      state.currentWorkflowId = action.payload;
    },
    setIsWorkflowDialogOpen: (
      state: Draft<IDialogsState>,
      action: PayloadAction<boolean>
    ) => {
      state.isWorkflowDialogOpen = action.payload;
    },
    setIsSelectWorkflowDialogOpen: (
      state: Draft<IDialogsState>,
      action: PayloadAction<boolean>
    ) => {
      state.isSelectWorkflowDialogOpen = action.payload;
    },
    setIsColorPickerOpen: (
      state: Draft<IDialogsState>,
      action: PayloadAction<boolean>
    ) => {
      state.isColorPickerOpen = action.payload;
    },
    setColumns: (state: Draft<IDialogsState>, action: PayloadAction<any>) => {
      state.columns = action.payload;
    },
  },
});

export const {
  setIsInviteMemberDialogOpen,
  setIsCompanyAddressDialogOpen,
  setIsLimitDialogOpen,
  setIsLimitConfirmationDialogOpen,
  setIsDuplicateDialogOpen,
  setDuplicateJobUrlKey,
  setIsSelectCustomerDialogOpen,
  setIsTalentPoolsDialogOpen,
  setIsChangePasswordDialogOpen,
  setIsPasswordNotificationShowed,
  extendLimit,
  extendLimitFailed,
  extendLimitSuccess,
  setPreviewImage,
  setIsUploadPictureDialogOpen,
  setArchiveRestoreDialogOpen,
  setIsBuyProductDialogOpen,
  setCurrentWorkflowId,
  setIsWorkflowDialogOpen,
  setIsColorPickerOpen,
  setIsSelectWorkflowDialogOpen,
  setColumns,
} = DialogsSlice.actions;

export default DialogsSlice.reducer;
