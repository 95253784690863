import { RootState } from "../config";

export const getAnalyticsState = ({ analytics }: RootState) => analytics;

export const getAnalyticsNumberOfApplicationsState = ({
  analytics
}: RootState) => analytics.numberOfApplications;

export const getAnalyticsNOAIsLoadingState = ({ analytics }: RootState) =>
  analytics.numberOfApplications.isLoading;

export const getAnalyticsNOATargetValues = ({ analytics }: RootState) =>
  analytics.numberOfApplications.valuesByDay;

export const getAnalyticsNOATargetLabels = ({ analytics }: RootState) =>
  analytics.numberOfApplications.labelsByDay;

export const getAnalyticsSOHTargetValues = ({ analytics }: RootState) =>
  analytics.numberOfApplications.sources.map(({ quantity }) => quantity);

export const getAnalyticsSOHTargetSources = ({ analytics }: RootState) =>
  analytics.numberOfApplications.sources.map(({ source }) => source);

export const getAnalyticsJobList = ({ analytics }: RootState) =>
  analytics.jobList;

export const getAnalyticsSelectedJob = ({ analytics }: RootState) =>
  analytics.selectedJob;

export const getAnalyticsIsLoading = ({ analytics }: RootState) =>
  analytics.isLoading;
