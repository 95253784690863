import { styled } from "@mui/system";
import { IconButton, MenuItem, Stack } from "@mui/material";

export const StyledHeaderContainer = styled(Stack)({
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  ".menu-icon": {
    width: 24,
    height: 24,
    stroke: "#131E30",
    cursor: "pointer",
  },
  ".supplier-logo": {
    height: 28,
  },
});

export const StyledHeaderTitleContainer = styled(Stack)({
  alignItems: "center",
  maxHeight: 30,
  svg: { cursor: "pointer", width: 24, height: 24 },
  ".menu-icon": {
    width: 24,
    height: 24,
    stroke: "#131E30",
    cursor: "pointer",
  },
});

export const StyledHeaderTitle = styled(Stack)(
  ({ desktop }: { desktop: boolean }) => ({
    maxWidth: desktop ? "50vw" : "auto",
    textOverflow: "ellipsis",
    overflow: desktop ? "hidden" : "auto",
    whiteSpace: desktop ? "nowrap" : "pre-line",
    fontSize: 20,
    fontWeight: 500,
    lineHeight: desktop ? "28px" : "auto",
    letterSpacing: -0.4,
    color: "#131E30",
    alignItems: "center",
    justifyContent: "center",
  })
);

export const StyledHeaderSubtitle = styled(Stack)({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "20px",
  color: "#6B7280",
  ".withdraw-application": {
    color: "#178CF2 !important",
    cursor: "pointer",
    zIndex: 2,
  },
});

export const StyledMobileMessagesHeaderSubtitles = styled(Stack)({
  flexDirection: "row",
  fontSize: 12,
  fontWeight: 400,
  lineHeight: "20px",
  color: "#6B7280",
  gap: 8,
  ".job-title": {
    maxWidth: "35%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#178CF2",
    cursor: "pointer",
  },
  ".job-title-text": {
    width: "fit-content",
    whiteSpace: "nowrap",
  },
});

export const StyledStatusContainer = styled(Stack)({
  alignItems: "center",
  textTransform: "capitalize",
});

export const StyledMenuButton = styled(IconButton)(
  ({ customStroke }: { customStroke?: string }) => ({
    width: 36,
    height: 36,
    padding: "0px!important",
    svg: {
      width: 24,
      height: 24,
      stroke: customStroke ?? "#131E30",
    },
  })
);

export const StyledMenuItem = styled(MenuItem)({
  fontSize: 14,
  fontWeight: 400,
  color: "#6B7280",
});
