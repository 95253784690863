import React from "react";
import { ArrowDownTrayIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import { Button } from "@mui/material";
import { IApplicationsToolbar } from "../../models/Applications";
import {
  StyledApplicationsToolbarContainer,
  StyledAddApplicantsButton,
} from "./styles";

const ApplicationsToolbar = ({
  t,
  selectedApplications,
  showAddApplicationButton = false,
  onExport,
  onOpenApplyJobDialog,
}: IApplicationsToolbar) => (
  <StyledApplicationsToolbarContainer
    data-testid={"applications-list-toolbar-wrapper"}
  >
    <Button
      data-testid={"applications-list-export-button"}
      disabled={selectedApplications.length === 0}
      startIcon={<ArrowDownTrayIcon className={"btn-icon"} />}
      onClick={() => onExport && onExport(selectedApplications)}
    >
      {t("joblist.export")}
    </Button>
    {showAddApplicationButton && (
      <StyledAddApplicantsButton
        onClick={onOpenApplyJobDialog}
        variant="contained"
        startIcon={<UserPlusIcon />}
      >
        {t("joblist.add")}
      </StyledAddApplicantsButton>
    )}
  </StyledApplicationsToolbarContainer>
);

export default ApplicationsToolbar;
