import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import PremiumJobBoardsComponent from "./component";
import {
  TConvertedPremiumProduct,
  TPremiumPackage,
  TPremiumProductItem,
} from "../../../models/SingleJob";
import {
  jobSliceSelectors,
  setSelectedProducts,
} from "../../../slices/Job/jobSlice";
import {
  fetchJobData,
  fetchProductsAndPackages,
  resetProductsFilter,
} from "../../../store_new/reducers/SingleJob";
import {
  getSingleJobFilteredProducts,
  getSingleJobFilteredShopProducts,
  getSingleJobState,
} from "../../../store_new/selectors/SingleJob";
import { getJobEditorState } from "../../../store_new/selectors/JobEditor";
import { setActiveProduct } from "../../../store_new/reducers/CurrentUser";
import { getCurrentUserData } from "../../../store_new/selectors/CurrentUser";

const PremiumJobBoards_new = ({
  isDiscoverPage,
  onOpenCart,
}: {
  readonly isDiscoverPage?: boolean;
  readonly onOpenCart: () => void;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { key: id } = useParams();
  const [displayAllPackages, setDisplayAllPackages] = useState(false);
  const products = useSelector(getSingleJobFilteredProducts);
  const shopProducts = useSelector(getSingleJobFilteredShopProducts);
  const selectedProducts = useSelector(jobSliceSelectors.selectedProducts); // TODO refactor later (cart component)
  const { isAgency } = useSelector(getCurrentUserData);
  const { isJobEditorOpen } = useSelector(getJobEditorState);
  const { shopPackages, packages, jobDetails, isLoading, totalShopProducts } =
    useSelector(getSingleJobState);

  const isCartIcon = !isDiscoverPage && !isJobEditorOpen;

  const handleOnAddPackage = (jPackage: TPremiumPackage) => {
    dispatch(
      // TODO refactor later (cart component)
      setSelectedProducts([
        ...selectedProducts,
        {
          ...jPackage,
          name: jPackage.package_name,
          retail_price: jPackage.package_price,
          market_price: jPackage.package_price,
          recommended_retail_price: jPackage.oldPrice,
          discount_price: jPackage.package_price,
        },
      ])
    );
    onOpenCart();
  };

  const handleOnAddProduct = (item: TPremiumProductItem) => {
    dispatch(
      // TODO refactor later (cart component)
      setSelectedProducts([
        ...selectedProducts,
        {
          ...item,
          discount_price: item.recommended_retail_price - item.retail_price,
        },
      ])
    );
    onOpenCart();
  };

  const handleOnRemoveFromCart = (
    item: TPremiumPackage | TPremiumProductItem
  ) => {
    dispatch(
      // TODO refactor later (cart component)
      setSelectedProducts(
        selectedProducts.filter(
          (selectedProduct: TPremiumPackage | TPremiumProductItem) =>
            selectedProduct.id !== item.id
        )
      )
    );
  };
  const isSelected = (currentItem: TPremiumProductItem | TPremiumPackage) => {
    return selectedProducts.some(
      (item: TPremiumProductItem | TPremiumPackage) =>
        item.id === currentItem.id
    );
  };

  const onResetFilters = () => dispatch(resetProductsFilter());

  const onOpenProduct = (
    product: TConvertedPremiumProduct | TPremiumPackage
  ) => {
    if (isDiscoverPage) {
      dispatch(
        setActiveProduct(product as unknown as TConvertedPremiumProduct)
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (product.package_name) {
        navigate("/package");
      } else {
        navigate("/product");
      }
    }
  };

  useEffect(() => {
    if (jobDetails?.url_key && !packages && !products && !isDiscoverPage) {
      dispatch(
        fetchProductsAndPackages({
          urlKey: jobDetails.url_key,
        })
      );
    } else {
      if (id && isAgency) {
        dispatch(
          fetchJobData({
            jobId: id,
            callback: () => {},
          })
        );
      }
    }
  }, [jobDetails]);

  useEffect(() => {
    if (jobDetails?.url_key) {
      dispatch(
        fetchProductsAndPackages({
          urlKey: jobDetails.url_key,
        })
      );
    }
  }, [jobDetails]);

  useEffect(() => {
    return () => {
      dispatch(resetProductsFilter());
    };
  }, []);

  return (
    <PremiumJobBoardsComponent
      t={t}
      isLoading={isLoading}
      packages={isDiscoverPage ? shopPackages : packages}
      products={isDiscoverPage ? shopProducts : products}
      displayAllPackages={displayAllPackages}
      selectedProducts={selectedProducts}
      isCartIcon={isCartIcon}
      totalShopProducts={totalShopProducts}
      isDiscoverPage={isDiscoverPage}
      onOpenCart={onOpenCart}
      isSelected={isSelected}
      onAddPackage={handleOnAddPackage}
      onAddProduct={handleOnAddProduct}
      onRemoveFromCart={handleOnRemoveFromCart}
      onOpenProduct={onOpenProduct}
      onResetFilters={onResetFilters}
      onDisplayAllPackages={() => {
        setDisplayAllPackages(!displayAllPackages);
      }}
    />
  );
};

export default PremiumJobBoards_new;
