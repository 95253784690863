import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { PayloadActionWithCallback } from "../../models/common";
import {
  TJobBasicQuestionsFormFields,
  TJobDataState,
  TJobDescriptionFormFields,
  TJobDetailsFormFields,
  TJobDetailsUpdateJobPayload,
} from "../../models/JobEditor";

export interface IJobEditorState {
  readonly jobDescription: TJobDescriptionFormFields;
  readonly jobDetails: TJobDetailsFormFields;
  readonly jobApplicationProcess: TJobBasicQuestionsFormFields;
  readonly jobData: TJobDataState;
  readonly isJobEditorOpen: boolean;
  readonly url_key: null | string;
  readonly isCheckoutOpen: boolean;
  readonly completedSteps: number[];
  readonly currentStep: number;
  readonly error: unknown;
  readonly isLoading: boolean;
  readonly companyId?: string | number;
  readonly packagesTotalPrice: number;
  readonly updateDraftInProgress: boolean;
  readonly draftData: any;
  readonly jobDescriptionData?: TJobDescriptionFormFields | null;
}

const initialState: IJobEditorState = {
  jobDescription: {
    header_1: "",
    header_2: "",
    header_3: "",
    logo: "",
    background: "",
    company_color: "#178CF2", // default color
    background_color: "#FFFFFF", // default color
    job_title: "",
    introduction_title: "",
    introduction: "",
    tasks_title: "",
    tasks: "",
    profile_title: "",
    profile: "",
    offer_title: "",
    offer: "",
    contact_title: "",
    contact: "",
    video_url: "",
  },
  jobDetails: {
    locations: [{ country: "", city: "", zip: "" }],
    contract_type: "",
    min_salary: "",
    max_salary: "",
    salary_type: "",
    keywords: "",
    notes: "",
    salary_currency: "",
    assign_job_to: "",
    position_type: "",
    reference_number: "",
    qualification: "",
    seniority: "",
    industry: "",
    field_of_work: "",
    workflow: "",
    date_of_publication: undefined,
  },
  jobApplicationProcess: {
    phone_required: "1",
    location_required: "1",
    salary_expectation_required: "0",
    earliest_start_date_required: "0",
    driver_license_required: "0",
    current_professional_status_required: "0",
    highest_degree_required: "0",
    langs_required: "0",
    skills_required: "0",
    questions: [],
  },
  jobData: {
    companyMembers: [],
    contractTypes: [],
    countries: [],
    documentsSetup: [],
    industriesList: [],
    positionTypes: [],
    qualificationsList: [],
    questionsSetup: [],
    salaryCurrencies: [],
    salaryTypes: [],
    seniorities: [],
    workFields: [],
  },
  isJobEditorOpen: false,
  url_key: null,
  isCheckoutOpen: false,
  completedSteps: [],
  currentStep: 1,
  error: false,
  isLoading: false,
  companyId: undefined,
  packagesTotalPrice: 0,
  updateDraftInProgress: false,
  draftData: undefined,
  jobDescriptionData: undefined,
};

const jobDescriptionReducers = {
  updateJob: (
    state: Draft<IJobEditorState>,
    _: PayloadActionWithCallback<{
      apiPayload: TJobDetailsUpdateJobPayload;
      url_key: string;
    }>
  ) => {
    state.isLoading = true;
  },
  updateJobSuccess: (
    state: Draft<IJobEditorState>,
    _: PayloadAction<{ url_key: string }>
  ) => {
    state.error = false;
    state.isLoading = false;
  },
  updateJobFailed: (
    state: Draft<IJobEditorState>,
    action: PayloadAction<unknown>
  ) => {
    state.error = action.payload;
    state.isLoading = true;
  },
  updateCompanyId: (
    state: Draft<IJobEditorState>,
    action: PayloadAction<number>
  ) => {
    state.companyId = action.payload;
  },
};

const jobEditorFormReducers = {
  setJobUrlKey: (
    state: Draft<IJobEditorState>,
    action: PayloadAction<string>
  ) => {
    state.url_key = action.payload;
  },
  updateJobDescription: (
    state: Draft<IJobEditorState>,
    action: PayloadAction<{ jobDescription: TJobDescriptionFormFields }>
  ) => {
    const { jobDescription } = action.payload;
    state.jobDescription = jobDescription;
  },
  updateJobDetails: (
    state: Draft<IJobEditorState>,
    action: PayloadAction<{ jobDetails: TJobDetailsFormFields }>
  ) => {
    const { jobDetails } = action.payload;
    state.jobDetails = JSON.parse(JSON.stringify(jobDetails));
  },
  updateJobApplicationProcess: (
    state: Draft<IJobEditorState>,
    action: PayloadAction<{ jobApplicationProcess: any }>
  ) => {
    const { jobApplicationProcess } = action.payload;
    state.jobApplicationProcess = JSON.parse(
      JSON.stringify(jobApplicationProcess)
    );
  },
  createJob: (
    state: Draft<IJobEditorState>,
    _: PayloadActionWithCallback<undefined>
  ) => {
    state.isLoading = true;
  },
  createJobSuccess: (
    state: Draft<IJobEditorState>,
    action: PayloadAction<{ url_key: string }>
  ) => {
    const { url_key } = action.payload;
    state.isLoading = false;
    state.url_key = url_key;
  },
  createJobFailed: (
    state: Draft<IJobEditorState>,
    action: PayloadAction<unknown>
  ) => {
    state.isLoading = false;
    state.error = action.payload;
  },
  saveDraft: (
    state: Draft<IJobEditorState>,
    _: PayloadActionWithCallback<{
      formValues: any;
    }>
  ) => {
    state.isLoading = true;
  },
  saveDraftSuccess: (state: Draft<IJobEditorState>) => {
    state.error = false;
    state.isLoading = false;
  },
  saveDraftFailed: (
    state: Draft<IJobEditorState>,
    action: PayloadAction<unknown>
  ) => {
    state.error = action.payload;
    state.isLoading = false;
  },
  updateDraft: (
    state: Draft<IJobEditorState>,
    _: PayloadActionWithCallback<{
      formValues: any;
      jobUrlKey: string;
    }>
  ) => {
    state.isLoading = true;
  },
  updateDraftSuccess: (state: Draft<IJobEditorState>) => {
    state.error = false;
    state.isLoading = false;
  },
  updateDraftFailed: (
    state: Draft<IJobEditorState>,
    action: PayloadAction<unknown>
  ) => {
    state.error = action.payload;
    state.isLoading = false;
  },
};

const jobDataReducers = {
  fetchJobData: (state: Draft<IJobEditorState>) => {
    state.isLoading = true;
  },
  fetchJobDataSuccess: (
    state: Draft<IJobEditorState>,
    action: PayloadAction<TJobDataState>
  ) => {
    state.jobData = action.payload;
    state.error = false;
    state.isLoading = false;
  },
  fetchJobDataFailed: (
    state: Draft<IJobEditorState>,
    action: PayloadAction<unknown>
  ) => {
    state.error = action.payload;
    state.isLoading = true;
  },
  resetJobData: (state: Draft<IJobEditorState>) => {
    state.jobData = initialState.jobData;
  },
  fetchDraftData: (
    state: Draft<IJobEditorState>,
    _: PayloadActionWithCallback<{
      jobUrlKey: string;
    }>
  ) => {
    state.isLoading = true;
  },
  fetchDraftDataSuccess: (
    state: Draft<IJobEditorState>,
    action: PayloadAction<unknown>
  ) => {
    state.error = false;
    state.isLoading = false;
    state.draftData = action.payload;
  },
  fetchDraftDataDataFailed: (
    state: Draft<IJobEditorState>,
    action: PayloadAction<unknown>
  ) => {
    state.error = action.payload;
    state.isLoading = true;
  },
  resetDraftData: (state: Draft<IJobEditorState>) => {
    state.draftData = initialState.draftData;
  },
  setJobDescripitonData: (
    state: Draft<IJobEditorState>,
    action: PayloadAction<TJobDescriptionFormFields | null>
  ) => {
    state.jobDescriptionData = action.payload;
  },
};

const stepperReducers = {
  setCompletedStep: (
    state: Draft<IJobEditorState>,
    action: PayloadAction<number>
  ) => {
    state.completedSteps = [...state.completedSteps, action.payload];
  },
  updateCompletedStep: (
    state: Draft<IJobEditorState>,
    action: PayloadAction<number[]>
  ) => {
    state.completedSteps = action.payload;
  },
  resetSteps: (state: Draft<IJobEditorState>) => {
    state.currentStep = initialState.currentStep;
    state.completedSteps = initialState.completedSteps;
  },
  setJobEditorStep: (
    state: Draft<IJobEditorState>,
    action: PayloadAction<number>
  ) => {
    state.currentStep = action.payload;
  },
  setUpdateDraftInProgress: (
    state: Draft<IJobEditorState>,
    action: PayloadAction<boolean>
  ) => {
    state.updateDraftInProgress = action.payload;
  },
};

const previewReducers = {
  previewJobTemplate: (
    state: Draft<IJobEditorState>,
    _: PayloadActionWithCallback<{ formValues: TJobDescriptionFormFields }>
  ) => {
    state.isLoading = true;
  },
  previewJobTemplateSuccess: (state: Draft<IJobEditorState>) => {
    state.isLoading = false;
  },
  previewJobTemplateFailed: (
    state: Draft<IJobEditorState>,
    action: PayloadAction<unknown>
  ) => {
    state.isLoading = false;
    state.error = action.payload;
  },
};

export const JobEditorSlice = createSlice({
  name: "JOB_EDITOR",
  initialState,
  reducers: {
    ...jobDescriptionReducers,
    ...jobEditorFormReducers,
    ...jobDataReducers,
    ...stepperReducers,
    ...previewReducers,
    setDefaultLogo: (state, action: PayloadAction<File>) => {
      state.jobDescription.logo = action.payload as File;
    },
    openJobEditor: (state) => {
      state.isJobEditorOpen = true;
    },
    closeJobEditor: (state) => {
      state.isJobEditorOpen = false;
    },
    openCheckout: (state) => {
      state.isCheckoutOpen = true;
    },
    closeCheckout: (state) => {
      state.isCheckoutOpen = false;
    },
    publishProducts: (state, _: PayloadActionWithCallback<undefined>) => {
      state.isLoading = true;
    },
    publishProductsSuccess: (state) => {
      state.isLoading = false;
    },
    publishProductsFailed: (state, action: PayloadAction<unknown>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    setAgencyPackagePrice: (state, _: PayloadAction<unknown>) => {
      state.isLoading = true;
    },
    setAgencyPackagePriceFailed: (state, action: PayloadAction<unknown>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    setAgencyPackagePriceSuccess: (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.packagesTotalPrice = action.payload.packagesTotalPrice;
    },
    resetAgencyPackagePrice: (state) => {
      state.packagesTotalPrice = initialState.packagesTotalPrice;
    },
    resetJobDescription: (state) => {
      state.jobDescription = initialState.jobDescription;
    },
    resetJobEditor: () => initialState,
  },
});

export const {
  setDefaultLogo,
  updateJob,
  updateJobSuccess,
  updateJobFailed,
  saveDraft,
  saveDraftFailed,
  saveDraftSuccess,
  updateJobDescription,
  updateJobDetails,
  updateJobApplicationProcess,
  fetchJobData,
  fetchJobDataSuccess,
  fetchJobDataFailed,
  setCompletedStep,
  setJobEditorStep,
  previewJobTemplate,
  previewJobTemplateSuccess,
  previewJobTemplateFailed,
  createJob,
  createJobSuccess,
  createJobFailed,
  openJobEditor,
  closeJobEditor,
  openCheckout,
  closeCheckout,
  publishProducts,
  publishProductsSuccess,
  publishProductsFailed,
  updateCompanyId,
  resetJobEditor,
  setAgencyPackagePrice,
  setAgencyPackagePriceFailed,
  setAgencyPackagePriceSuccess,
  resetAgencyPackagePrice,
  setUpdateDraftInProgress,
  updateDraft,
  updateDraftSuccess,
  updateDraftFailed,
  fetchDraftData,
  fetchDraftDataDataFailed,
  fetchDraftDataSuccess,
  updateCompletedStep,
  resetSteps,
  resetDraftData,
  resetJobDescription,
  setJobDescripitonData,
  resetJobData,
  setJobUrlKey,
} = JobEditorSlice.actions;

export default JobEditorSlice.reducer;
