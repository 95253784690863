import { Box, Stack, Typography, Link, Divider } from "@mui/material";
import { styled } from "@mui/system";

export const StyledMessagesContainer = styled(Box)({
  marginTop: 24,
  backgroundColor: "#fff",
  width: "100%",
  minHeight: "82vh",
  maxHeight: "82vh",
  borderRadius: 6
});

export const StyledHeaderContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  height: 88,
  alignItems: "center",
  borderBottom: "1px solid #E5E7EB",
  borderRadius: 6
});

export const StyledLeftHeaderSideContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  height: "100%",
  width: "345px",
  padding: 24,
  borderRight: "1px solid #E5E7EB",
  alignItems: "center",
  justifyContent: "space-between"
});

export const StyledRightHeaderSideContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  width: "calc(100% - 345px)",
  padding: 24,
  alignItems: "center",
  justifyContent: "space-between"
});

export const StyledJobTitleTypography = styled(Typography)({
  maxWidth: "95%",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  cursor: "pointer",
  display: "inline",
  color: "#178CF2",
  fontSize: 12
});

export const StyledHeaderJobTitleTypography = styled(Typography)({
  maxWidth: "65%",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  cursor: "pointer",
  display: "inline",
  color: "#178CF2",
  fontSize: 12
});

export const StyledJobTitleHeaderTypography = styled(Typography)({
  display: "inline",
  cursor: "pointer",
  color: "#178CF2",
  fontSize: 12,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap"
});

export const StyledSmallTypography = styled(Typography)({
  color: "#9CA3AF",
  fontSize: 12
});

export const StyledSubtitleTypography = styled("span")({
  color: "#6B7280",
  fontSize: 12
});

export const StyledNameTypography = styled(Typography)({
  color: "#131E30",
  maxWidth: "500px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  fontSize: 16,
  fontWeight: 500
});

export const StyledUserNameTypography = styled(Typography)({
  maxWidth: 132,
  color: "#131E30",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  fontSize: 16,
  fontWeight: 500
});

export const StyledEditedMessageTypography = styled(Typography)({
  color: "#6B7280",
  fontSize: 16,
  fontWeight: 500
});

export const StyledMessageTypography = styled(Typography)({
  fontSize: 14,
  color: "#6B7280",
  whiteSpace: "pre-line",
  wordBreak: "break-word"
});

export const StyledLastMessageTypography = styled(Typography)({
  fontSize: 14,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap"
});

export const StyledChatContainer = styled(Box)({
  display: "flex",
  flexDirection: "row"
});

export const StyledChatUsersContainer = styled(Stack)({
  overflow: "auto",
  "&::-webkit-scrollbar ": {
    width: "4px"
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#E5E7EB",
    borderRadius: "4px"
  }
});

export const StyledChatUser = styled(Stack)({
  padding: "16px 24px",
  cursor: "pointer"
});

export const StyledFullMessagesContainer = styled(Stack)({
  zIndex: 1,
  position: "relative",
  width: "100%"
});

export const StyledMessagesListContainer = styled(Stack)({
  padding: 24,
  gap: 10,
  overflowY: "scroll",
  "&::-webkit-scrollbar ": {
    width: "4px"
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#E5E7EB",
    borderRadius: "4px"
  }
});

export const StyledNewMessageContainer = styled(Stack)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-end",
  gap: 24
});

export const StyledChatTextarea = styled("textarea")({
  width: "100%",
  border: "1px solid #E5E7EB",
  borderRadius: 6,
  resize: "none",
  padding: 8,
  fontFamily: "Roboto,Helvetica,Arial,sans-serif",
  fontSize: 14,
  "&:focus": {
    outline: "none !important",
    border: "1px solid #178CF2",
    boxShadow: "0 0 5px 2px #C6E5FF"
  },
  "&::-webkit-scrollbar ": {
    width: "4px"
  },

  "&::-webkit-scrollbar-thumb": {
    background: "#E5E7EB",
    borderRadius: "4px"
  }
});

export const StyledMobileChatTextarea = styled("textarea")({
  height: 40,
  padding: 10,
  fontFamily: "Roboto,Helvetica,Arial,sans-serif",
  fontSize: 14,
  width: "80%",
  borderRadius: 6,
  resize: "none",
  border: "none",
  "&:focus": {
    outline: "none !important",
    border: "none !important",
    boxShadow: "none !important"
  },
  "&::-webkit-scrollbar ": {
    width: "4px"
  },

  "&::-webkit-scrollbar-thumb": {
    background: "#E5E7EB",
    borderRadius: "4px"
  }
});

export const StyledLink = styled(Link)({
  fontSize: 14,
  textDecorationColor: "#178CF2"
});

export const StyledChatDivider = styled(Divider)({
  position: "absolute",
  right: 24,
  width: "100vw"
});

export const StyledNewMessageInfoContainer = styled(Stack)({
  color: "#fff",
  background: "#3B82F6",
  width: 40,
  height: 20,
  borderRadius: 100,
  fontSize: 12,
  padding: "0px 8px",
  alignItems: "center",
  justifyContent: "center"
});

export const StyledPoint = styled(Stack)({
  background: "#3B82F6",
  width: 8,
  height: 8,
  borderRadius: "50%",
  alignSelf: "center"
});

export const StyledIconHovered = styled(Stack)(
  ({ desktop, isCandidate }: { desktop: boolean; isCandidate?: boolean }) => ({
    position: "absolute",
    top: isCandidate && !desktop ? 20 : 0,
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    width: 30,
    height: 30,
    zIndex: 2,
    "&: hover": {
      background: "#9f9d9d29"
    }
  })
);
