import React from "react";
import { Menu, Skeleton, Stack } from "@mui/material";
import {
  ArrowLeftIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/24/outline";
import TeamMembers from "../../DataGrid_new/cellRenderers/members";
import { ISingleJobHeaderComponent } from "../../../models/TopHeader";
import {
  StyledHeaderSubtitle,
  StyledHeaderTitle,
  StyledHeaderTitleContainer,
  StyledMenuButton,
  StyledMenuItem,
} from "../styles";

const SingleJobHeaderComponent = ({
  t,
  title,
  subtitle,
  anchorEl,
  jobOwners,
  isLoading,
  urlKey,
  isJobOwner,
  isArchivedJob,
  desktop,
  workflows,
  onOpenSetWorkflowDialog,
  onOpenMenu,
  onCloseMenu,
  onEdit,
  onArchive,
  onPreview,
  onDuplicate,
  onInvite,
  onExport,
  onNavigateBack,
}: ISingleJobHeaderComponent) => (
  <Stack>
    <StyledHeaderTitleContainer direction={"row"} spacing={2}>
      <StyledMenuButton onClick={onNavigateBack}>
        <ArrowLeftIcon />
      </StyledMenuButton>
      {isLoading ? (
        <Skeleton variant={"rounded"} width={200} height={24} />
      ) : (
        <StyledHeaderTitle desktop={desktop}>{title}</StyledHeaderTitle>
      )}
      {isLoading || !urlKey ? (
        <Skeleton variant={"circular"} width={36} height={36} />
      ) : (
        <Stack direction={"row"} alignItems={"center"}>
          {!!jobOwners?.length && (
            <TeamMembers owners={jobOwners} showInJobHeader />
          )}
          {isLoading || !urlKey ? (
            <Skeleton variant={"rounded"} width={36} height={36} />
          ) : (
            <StyledMenuButton onClick={onOpenMenu}>
              <EllipsisVerticalIcon />
            </StyledMenuButton>
          )}
        </Stack>
      )}

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {urlKey && (
          <StyledMenuItem onClick={onDuplicate}>
            {t("joblist.duplicate")}
          </StyledMenuItem>
        )}
        <StyledMenuItem onClick={onEdit}>{t("single_job.edit")}</StyledMenuItem>
        {urlKey && (
          <StyledMenuItem onClick={onPreview}>
            {t("create_job_first_step.preview")}
          </StyledMenuItem>
        )}
        {urlKey && (
          <StyledMenuItem onClick={onArchive}>
            {t(isArchivedJob ? "joblist.activate" : "joblist.archive")}
          </StyledMenuItem>
        )}
        {isJobOwner && (
          <StyledMenuItem onClick={onInvite}>
            {t("team_members.invite_team")}
          </StyledMenuItem>
        )}
        <StyledMenuItem onClick={onExport}>
          {t("joblist.export")}
        </StyledMenuItem>
        <StyledMenuItem onClick={onOpenSetWorkflowDialog}>
          {workflows.length > 0
            ? t("workflow.setWorkflow")
            : t("workflow.addWorkflow")}
        </StyledMenuItem>
      </Menu>
    </StyledHeaderTitleContainer>
    <Stack pl={5} pt={0.25}>
      {isLoading ? (
        <Skeleton variant={"rounded"} width={100} height={20} />
      ) : (
        <StyledHeaderSubtitle pl={1.5}>{subtitle}</StyledHeaderSubtitle>
      )}
    </Stack>
  </Stack>
);

export default SingleJobHeaderComponent;
