import React, { useState, useEffect } from "react";
import { Box, Button, Stack, Tooltip, Typography } from "@mui/material";
import {
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ClockIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/outline";
import { motion, AnimatePresence } from "framer-motion";
import {
  IPremiumPackagesComponent,
  TSalesProduct,
} from "../../../models/SingleJob";
import {
  StyledDaysBlock,
  StyledDiscountLabel,
  StyledDivider,
  StyledPackageBodyItem,
  StyledPackageContainer,
  StyledPackageContainerHeader,
  StyledPackagePriceBlock,
  StyledPremiumPackagesProductsContainer,
  StyledSuccessButton,
} from "../styles";

const PremiumPackagesComponent = ({
  t,
  packages,
  displayAllPackages,
  isDiscoverPage,
  onOpenProduct,
  isSelected,
  onAddPackage,
  onRemoveFromCart,
  onDisplayAllPackages,
}: IPremiumPackagesComponent) => {
  const [height, setHeight] = useState("auto");

  useEffect(() => {
    setHeight("auto");
  }, [displayAllPackages]);

  return (
    <StyledPremiumPackagesProductsContainer>
      <Stack flex={1}>
        <Typography variant="body1">
          {t("create_job_fourth_step.packages")}
        </Typography>
      </Stack>
      <StyledDivider />
      <motion.div
        initial={{ height: 0 }}
        animate={{ height }}
        transition={{ duration: 0.5 }}
      >
        <Stack
          m={0}
          p={0}
          direction={"row"}
          flexWrap={"wrap"}
          gap={3}
          width="100%"
          pb={3}
        >
          <AnimatePresence>
            {packages &&
              (displayAllPackages ? packages : packages.slice(0, 3)).map(
                (jobPackage) => (
                  <motion.div
                    key={jobPackage.id}
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.8 }}
                    transition={{ duration: 0.3 }}
                    style={{
                      width: "calc(33.333% - 16px)",
                    }}
                  >
                    <StyledPackageContainer
                      isDiscoverPage={isDiscoverPage}
                      onClick={() => onOpenProduct(jobPackage)}
                    >
                      <StyledPackageContainerHeader>
                        <Typography variant="body1">
                          {jobPackage.package_name}
                        </Typography>
                        {jobPackage?.discount && jobPackage?.discount > 0 && (
                          <StyledDiscountLabel>
                            {jobPackage.discount} %
                          </StyledDiscountLabel>
                        )}
                      </StyledPackageContainerHeader>
                      <StyledDivider />
                      <Stack spacing={1} mt={2}>
                        {jobPackage.sales_products?.map(
                          (product: TSalesProduct, productIndex: number) => (
                            <StyledPackageBodyItem
                              spacing={1}
                              key={productIndex}
                            >
                              <img
                                src={product.image_url}
                                alt={product.supplier}
                              />
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                              >
                                <Stack>
                                  <Tooltip title={product.name}>
                                    <Typography
                                      variant="subtitle2"
                                      noWrap
                                      maxWidth={210}
                                      overflow="hidden"
                                      textOverflow="ellipsis"
                                    >
                                      {product.name}
                                    </Typography>
                                  </Tooltip>
                                </Stack>
                                <StyledDaysBlock alignSelf="flex-end">
                                  <ClockIcon />
                                  <span>
                                    {product.runtime_in_days}{" "}
                                    {t("create_job_fourth_step.days")}
                                  </span>
                                </StyledDaysBlock>
                              </Stack>
                            </StyledPackageBodyItem>
                          )
                        )}
                      </Stack>
                      <Stack mt={"auto"}>
                        <StyledPackagePriceBlock>
                          <Typography variant="subtitle2">
                            {t("create_job_fourth_step.price")}:
                          </Typography>
                          <Stack spacing={1} direction={"row"}>
                            <Box className={"old-price"}>
                              {jobPackage.oldPrice} €
                            </Box>
                            <Box className={"new-price"}>
                              {jobPackage.package_price} €
                            </Box>
                          </Stack>
                        </StyledPackagePriceBlock>
                        {!isDiscoverPage && (
                          <>
                            {isSelected(jobPackage) ? (
                              <StyledSuccessButton
                                variant={"contained"}
                                onClick={() => onRemoveFromCart(jobPackage)}
                              >
                                <Stack spacing={1.25} direction={"row"}>
                                  <CheckCircleIcon width={24} height={24} />
                                  <Box>
                                    {t("create_job_fourth_step.package_added")}
                                  </Box>
                                </Stack>
                              </StyledSuccessButton>
                            ) : (
                              <Button
                                variant={"outlined"}
                                onClick={() => onAddPackage(jobPackage)}
                              >
                                <Stack spacing={1.25} direction={"row"}>
                                  <PlusCircleIcon width={24} height={24} />
                                  <Box>
                                    {t("create_job_fourth_step.add_package")}
                                  </Box>
                                </Stack>
                              </Button>
                            )}
                          </>
                        )}
                      </Stack>
                    </StyledPackageContainer>
                  </motion.div>
                )
              )}
          </AnimatePresence>
        </Stack>
      </motion.div>
      {packages && packages?.length > 3 && (
        <Button onClick={onDisplayAllPackages} sx={{ marginTop: "32px" }}>
          <Typography variant="subtitle2" color="secondary" pr={0.5}>
            {displayAllPackages
              ? t("company_page.show_less")
              : t("create_job_fourth_step.show_all_packages")}
          </Typography>
          {displayAllPackages ? (
            <ChevronUpIcon width={24} height={24} />
          ) : (
            <ChevronDownIcon width={24} height={24} />
          )}
        </Button>
      )}
    </StyledPremiumPackagesProductsContainer>
  );
};

export default PremiumPackagesComponent;
