import React, { useRef } from "react";
import { Button, CircularProgress, Dialog } from "@mui/material";
import { TFunction } from "i18next";
import { useSelector } from "react-redux";
import JobDetailsForm from "../../../../components/JobDialogs_new/JobDetails";
import {
  TJobDetailsFormFields,
  TJobDetailsFormFieldsPayload,
} from "../../../../models/JobEditor";
import { SELECTED_EDIT_SECTIONS } from "../../../../models/SingleJob";
import { getJobEditorState } from "../../../../store_new/selectors/JobEditor";
import { getSingleJobState } from "../../../../store_new/selectors/SingleJob";
import { StyledDialogActions } from "../../styles";

const JobDetailsEditDialog = ({
  t,
  isOpen,
  onClose,
  onUpdateJob,
}: {
  t: TFunction;
  isOpen: boolean;
  onClose: () => void;
  onUpdateJob: (data: TJobDetailsFormFieldsPayload) => void;
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const { jobDetails, jobLocations } = useSelector(getSingleJobState);
  const { isLoading } = useSelector(getJobEditorState);

  const handleOnUpdateJobDetails = (data: TJobDetailsFormFields) => {
    if (jobDetails) {
      const {
        locations,
        contract_type,
        position_type,
        salary_type,
        field_of_work,
        assign_job_to,
        industry,
        max_salary,
        min_salary,
        salary_currency,
        seniority,
        qualification,
        notes,
        reference_number,
        keywords,
        date_of_publication,
        workflow,
      } = data;
      const apiPayload = {
        locations,
        contract_type,
        position_type,
        salary_type: salary_type || "",
        field_of_work,
        assign_job_to,
        industry,
        max_salary: max_salary || "",
        min_salary: min_salary || "",
        salary_currency,
        seniority,
        qualification,
        notes,
        handle: reference_number,
        keywords,
        date_of_publication,
        title: jobDetails.title,
        workflow: workflow as string,
      };
      onUpdateJob(apiPayload);
    }
  };
  return (
    <Dialog open={isOpen} maxWidth={"md"} fullWidth onClose={onClose}>
      {jobLocations && jobDetails && (
        <JobDetailsForm
          t={t}
          defaultValues={{
            ...jobDetails,
            industry: jobDetails.industry_id,
            contract_type: jobDetails.contract_type_id,
            field_of_work: jobDetails.work_id,
            seniority: jobDetails.seniority_id,
            position_type: jobDetails.position_id,
            qualification: jobDetails.qualification_id,
            salary_type: jobDetails.salary_type || "",
            keywords: jobDetails.keywords || "",
            reference_number: jobDetails.reference_number || "",
            locations: jobLocations,
            workflow: jobDetails.workflow_id || "",
          }}
          formRef={formRef}
          selectedSections={[
            SELECTED_EDIT_SECTIONS.JOB_DETAILS_LOCATIONS,
            SELECTED_EDIT_SECTIONS.JOB_DETAILS_GENERAL_INFO,
            SELECTED_EDIT_SECTIONS.JOB_DETAILS_SALARY,
          ]}
          onSubmit={handleOnUpdateJobDetails}
        />
      )}
      <StyledDialogActions>
        <Button variant="outlined" onClick={onClose} disabled={isLoading}>
          {t("button.secondCancel")}
        </Button>
        <Button
          variant="contained"
          type="submit"
          endIcon={
            isLoading ? (
              <CircularProgress
                size={16}
                style={{
                  color: "#9A9FAF",
                }}
              />
            ) : null
          }
          onClick={() => formRef.current?.requestSubmit()}
          disabled={isLoading}
        >
          {t("button.save")}
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
};

export default JobDetailsEditDialog;
