export const translationDe = {
  //  navigation
  navigation: {
    analytics: "Analysen",
    applications: "Bewerbungen",
    settings: "Einstellungen",
    company_profiles: "Allgemein",
    discover: "Entdecken",
    inventory: "Inventar",
    bookings: "Buchungen",
    create_job_ad: "Stellenanzeige erstellen",
    messages: "Nachrichten",
    profile: "Profil",
    customers: "Kunden",
    discover_all_products: "Entdecke alle Produkte",
    workflow: "Workflows",
  },

  //dashboard
  dashboard: {
    firstSteps: {
      title: "Deine ersten Schritte",
      stepOne: "Erstelle und aktiviere deinen Account",
      stepTwo: "Lade dein Unternehmenslogo hoch",
      stepThree: "Bearbeite deine Unternehmensseite",
      stepFour: "Füge dein LinkedIn-Unternehmensprofil hinzu",
      stepFive: "Füge dein Xing-Unternehmensprofil hinzu",
      stepSix: "Erstelle deine erste Stellenanzeige",
    },
    support: {
      title: "Support",
      ctaBtn: "Jetzt Unterstützung erhalten",
      footerText: "Wenn du Fragen hast, sende eine E-Mail an",
    },
    jobs: {
      title: "Deine Jobs",
      titleBtn: "Alle anzeigen",
      createBtn: "Stellenanzeige erstellen",
      applications: "Bewerbungen",
      emptyList: "Du hast noch keine Jobs",
    },
    applications: {
      title: "Deine Bewerbungen",
      titleBtn: "Alle anzeigen",
      emptyList: "Du hast noch keine Bewerbungen",
    },
  },

  // update company information
  update_company_information: {
    title: "Aktualisieren Sie Ihre Unternehmensdaten",
    textOne: "Bitte aktualisiere das Firmenprofil mit deinen Adressdaten,",
    textTwo: "um Karriera mit allen Funktionalitäten nutzen zu können.",
    country: "Land",
    city: "Stadt",
    postcode: "PLZ",
    street: "Straße",
    number: "Nummer",
    reference_number: "Referenznummer",
    keywords: "Schlagworte",
    success_snackbar: "Deine Adresse wurde erfolgreich aktualisiert.",
  },

  // create job dialog first step
  create_job_first_step: {
    preview: "Vorschau",
    save_as_draft: "Als Entwurf speichern",
    save_draft: "Entwurf speichern",
    continue_editing: "Bearbeitung fortsetzten",
    save_draft_first_text:
      "Möchten Sie die Erstellung Ihrer Stellenanzeige als Entwurf speichern?",
    save_draft_second_text: `Ihr Fortschritt wird gespeichert und ist über die Jobliste auf der Registerkarte "Entwurf" für eine spätere Bearbeitung zugänglich.`,
    create_job_ad: "Job erstellen",
    describe_job: "Stelle beschreiben",
    specify_job: "Stelle spezifizieren",
    application_process: "Bewerbungspozess",
    job_title: "Jobtitel",
    job_title_placeholder: " Jobtitel (m/w/d)",
    introduction_title: "Einleitung Titel",
    introduction_title_placeholder: "Über uns",
    introduction: "Einleitung",
    introduction_placeholder:
      "Beschreiben Sie kurz, was Ihr Unternehmen macht und wieso Sie ein attraktiver Arbeitgeber sind.",
    tasks_title: "Aufgaben Titel",
    tasks: "Aufgaben",
    tasks_placeholder:
      "Sie mögliche Aufgaben und Verantwortungsbereiche, damit potenzielle Bewerber verstehen, was diese Stelle attraktiv macht.",
    requirements_title: "Anforderungen Titel",
    requirements_title_placeholder: "Qualifikationen",
    requirements: "Anforderungen",
    requirements_placeholder:
      "Beschreiben Sie die Anforderungen der Stelle, um den Bewerbern einen guten Eindruck von der Position zu vermitteln.",
    benefits: "Benefits",
    benefits_title: "Benefits Titel",
    benefits_placeholder: "Benefits",
    contact_information_title: "Kontakinformationen Titel",
    contact_information: "Kontakinformationen",
    contact_information_placeholder: "Kontaktinformationen",
    video_placeholder: "Video URL",
    upload_photo: "Foto {{number}} hochladen",
    upload_logo: "Logo hochladen",
    upload_background_image: "Hintergrundbild hochladen",
    title_color: "Titelfarbe",
    background_color: "Hintergrundfarbe",
    photo_size: "PNG, JPG bis zu 5MB",
  },

  // create job dialog second step
  create_job_second_step: {
    location: "Arbeitsort",
    general_information: "Allgemeine Informationen",
    contract_type: "Vertragsart",
    field_of_work: "Tätigkeitsbereich",
    seniority: "Seniorität",
    job_type: "Jobtyp",
    additional_information: "Zusätzliche Informationen",
    reference_number: "Referenznummer",
    date_of_publication: "Datum der Veröffentlichung",
    assign_job_to: "Job zuweisen an",
    keywords: "Schlagworte",
    keywords_placeholder: "Schlagwort 1, Schlagwort 2, Schlagwort 3",
    notes: "Notizen",
    salary: "Gehalt",
    minimum_salary: "Mindestgehalt",
    minimum_salary_placeholder: "Gehalt Min.",
    maximum_salary: "Maximales Gehalt",
    maximum_salary_placeholder: "Gehalt Max.",
    currency: "Währung",
    interval: "Intervall",
    qualification_level: "Qualifikationsniveau",
  },

  // create job dialog third step
  create_job_third_step: {
    individualize_application_process: "Bewerbungsprozess individualisieren",
    basic_questions: "Allgemeine Fragen",
    phone_number: "Telefonnummer",
    salary_expectation: "Gehaltserwartung",
    earliest_start_date: "Startdatum",
    driving_licenses: "Führerschein",
    current_position: "Aktuelle Position",
    highest_degree: "Höchster Abschluss",
    language_skills: "Sprachkenntnisse",
    skills: "Fähigkeiten",
    documents: "Dokumente",
    additional_documents: "Zusätzliche Dokumente (max. 4 Dokumente)",
    additional_questions: "Zusätzliche Fragen",
    profile_photo: "Profilfoto",
    question: "Frage",
    answer: "Antwort",
    answer_type: "Antworttyp:",
    add_question: "Frage hinzufügen",
    free_text: "Freitext",
    yes_no: "Ja / Nein",
  },

  // create job dialog fourth step
  create_job_fourth_step: {
    search_job_board: "Jobörse suchen",
    industry: "Branche",
    country: "Land",
    productName: "Produktname",
    duration: "Laufzeit ",
    number_selected_products: "Produkt(e)",
    products: "Produkte",
    succes_heading: "Ihre Stellenanzeige wurde erfolgreich veröffentlicht.",
    succes_subheading_one:
      "Ihre Stellenanzeige wurde auf kostenlosen Plattformen veröffentlicht, die Ihnen bereits eine gute Sichtbarkeit bieten.",
    succes_subheading_two:
      "Nutzen Sie Premium-Stellenbörsen, um die Anzahl und Qualität der Talente weiter zu erhöhen.",
    add_package: "Paket hinzufügen",
    package_added: "Paket hinzugefügt",
    select_product: "Produkt auswählen",
    price: "Preis",
    create_job_and_publish_title: "Job erstellen und veröffentlichen",
    create_job_and_publish_text:
      "Du kannst einen Job erstellen, um dein Produkt zu buchen.",
    create_new_job: "Neuen Job erstellen",
    select_and_publish_job_title: "Job auswählen und veröffentlichen",
    select_and_publish_job_text:
      "Du kannst einen Job auswählen, um dein Produkt zu buchen.",
    show_all_packages: "Alle Pakete anzeigen",
    no_description: "Keine Beschreibung",
    packages: "Pakete",
    days: "Tage",
    filterDurations: {
      upTo30: "Bis 30 Tage",
      upTo60: "Bis 60 Tage",
      upTo90: "Bis 90 Tage",
      moreThan90: "Mehr also 90 Tage",
    },
    reference_number: "Referenznummer",
    keywords: "Schlagworte",
    success_title: "Du hast den Job erfolgreich erstellt",
    starting_from: "Ab",
  },

  // create job dialog fifth step
  create_job_fifth_step: {
    confirmation: "Bestellung abschließen",
    selected_products: "Ausgwählte Produkte",
    summary: "Zusammenfassung",
    original_price: "Urspungspreis",
    discount: "Nachlass",
    sub_total: "Zwischensumme",
    total: "Gesamtsumme",
    agency_price: "Agenturpreis",
    your_total: "Gesamtbetrag",
    customer_total: "Kundenpreis",
    termsFirst: "Mit Ihrer Bestellung stimmen Sie den",
    termsSecond: "AGBs",
    termsThird: "und die",
    termsFour: "Datenschutzbestimmungen",
    termsFive: "von Karriera zu.",
    place_your_order: "Bestellung abschließen",
    no_result: "Keine Ergebnisse?",
    using_filters: "Benutzen Sie eventuell Filter?",
    reset_filters: "Filter zurücksetzen",
    snackbar_title: "Du hast den Job erfolgreich veröffentlicht.",
    margin: "Marge",
  },

  // cart
  cart: {
    title: "Warenkorb",
    empty_title: "Dein Warenkorb ist leer",
    empty_text_one: "Sie haben noch nichts in Ihren Einkaufswagen gelegt.",
    empty_text_two: "Wählen Sie jetzt passende Produkte für Ihren Job.",
    empty_button: "Jetzt Produkte auswählen",
    checkout: "Zur Kasse gehen",
  },

  // additional questions
  additional_questions: {
    confirmation_title: "Wir haben Ihre Bewerbung erhalten.",
    confirmation_subtitle:
      "Um Ihre Bewerbung besser beurteilen zu können, möchten wir Sie um zusätzliche Informationen bitten.",
    phone_number: "Bitte geben Sie eine Telefonnummer an",
    location: "Bitte geben Sie Ihren derzeitigen Wohnsitz an",
    salary_expectation: "Bitte geben Sie Ihre Gehalterwartung an",
    start_date: "Bitte nennen Sie uns ihr frühestmögliches Startdatum",
    start_date_placeholder: "Datum auswählen",
    current_profession: "Was ist Ihr aktueller beruflicher Status",
    degree: "Was ist Ihr höchster Schul- oder Berufsabschluss?",
    driving_licenses: "Bitte nennen Sie uns Ihre Führerscheinklassen",
    driving_licenses_placeholder: "Select licenses",
    language: "Welche Sprachkenntnisse besitzen Sie",
    language_placeholder: "Sprache auswählen",
    language_placeholder_two: "Level",
    skills: "Bitte fügen SIe Ihre Fähigkeiten hinzu",
    skills_placeholder: "Fähigkeiten hinzufügen",
    documents: "Bitte fügen Sie ihre Dokumente hinzu",
    documents_placeholder: "Dokumente hochladen",
    profile_picture: "Bitte fügen Sie ein Profilfoto hinzu",
    profile_picture_placeholder: "Profilfoto hochladen",
    add_language: "Sprache hinzufügen",
    type_something_placeholder: "Schreibe etwas",
    city_no_options:
      "Wir konnten Ihre Stadt nicht finden. Bitte wählen Sie eine größere Stadt in ihrer Nähe.",
  },

  //  button
  button: {
    cancel: "Vorherige",
    next: "Nächste",
    save: "Speichern",
    secondCancel: "Abbrechen",
    continue: "Weiter",
    no_thanks: "Nein danke",
    skip: "Überspringen",
    submit: "Absenden",
    remove: "Entfernen",
  },

  // jobs
  jobs: {
    navigation: {
      all_jobs: "Alle Jobs",
      archived: "Archiviert",
    },
  },

  // verification resend ...
  verification: {
    title: "E-Mail Adresse bestätigen",
    email_subtitle_one: "Um die E-Mail Adresse zu bestätigen, klicke bitte",
    email_subtitle_two: "den Link in der E-Mail.",
    subtitle_one:
      "Wir haben den Bestätigungslink an Deine E-Mail-Adresse gesendet.",
    subtitle_two:
      "Die Bewerbung wird übermittelt, sobald die E-Mail-Adresse bestätigt ist.",
    resend: "E-Mail erneut senden",
  },

  // job list
  joblist: {
    search_placeholder: "Suche nach Jobtitel",
    search_by_company_placeholder: "Suche nach Unternehmensname",
    continue_editing: "Bearbeitung fortsetzen",
    location_placeholder: "Ort",
    seniority_placeholder: "Arbeitserfahrung",
    seniority: "Erfahrung",
    empty_button: "Stellenanzeige erstellen",
    reset_filters_button: "Filter zurücksetzen",
    empty_first_text: "Sie haben noch keinen Job.",
    empty_first_text_filters_exists:
      "Offenbar können keine übereinstimmenden Ergebnisse für Deinen Filter gefunden werden.",
    empty_second_text: "Erstellen Sie Ihre erste Stellenanzeige!",
    no_archived_job_title: "Sie haben noch keine archivierten Jobs.",
    no_draft_job_title: "Du hast noch keine Entwürfe.",
    title: "Titel",
    export: "Exportieren",
    archive: "Archivieren",
    add: "Bewerbung hinzufügen",
    promote: "Promoten",
    activate: "Aktivieren",
    duplicate: "Duplizieren",
    duplicate_dialog_title: "Stellenanzeige duplizieren",
    duplicate_dialog_subtitle: "Möchten Sie diese Stellenanzeige duplizieren?",
    duplicate_snackbar_title:
      "Du hast den Job erfolgreich dupliziert und unter Entwürfe abgelegt.",
    save_draft_snackbar_title:
      "Du hast den Entwurf erfolgreich gespeichert. Du kannst diesen Entwurf unter Entwürfe finden und ihn jederzeit bearbeiten.",
  },

  // application complete -
  application_complete: {
    title: "Ihre Bewerbung war erfolgreich!",
    subtitle:
      "Ihre Bewerbung wurde erfolgreich an das Unternehmen übermittelt.",
    dashboard_button: "Bewerbungsstatus ansehen",
  },

  // applications
  applications: {
    search_placeholder: "Suche nach Namen",
    job_placeholder: "Job auswählen",
    status_placeholder: "Status auswählen",
    name: "Name",
    applied_to: "Beworben auf",
    date_applied: "Beworben am",
    download_cv: "Lebenslauf herunterladen",
    empty_first_text: "Sie haben noch keine Bewerbungen",
    empty_second_text:
      "Veröffentlichen Sie Ihre Stelle auf unseren Premiumbörsen, um mehr Bewerbungen zu erhalten.",
    no_bookings_title:
      "Veröffentliche die Stelle jetzt auf Premiumportalen und erhalte bis zu 3x mehr Bewerberinnen.",
    empty_button: "Jetzt veröffentlichen",
    delete_application: "Bewerbung löschen",
    delete_application_text:
      "Wenn du die Bewerbung löschst, werden sie aus Karriera entfernt, so dass du keinen Zugriff mehr auf die Daten hast.",
    delete_multiple_application_text:
      "Wenn du die Bewerbungen löschst, werden sie aus Karriera entfernt, so dass du keinen Zugriff mehr auf die Daten hast.",
    delete_candidate_application_text:
      "Wenn du deine Bewerbung zurückziehst, wird sie aus Karriera entfernt, so dass die Recruiter keinen Zugriff mehr darauf haben. Bitte beachte, dass sie deine Daten möglicherweise bereits heruntergeladen haben. Bitte kontaktiere das Unternehmen direkt, um die Löschung deiner Daten aus dem System zu beantragen.",
    delete_application_note:
      "Diese Aktion kann nicht rückgängig gemacht werden.",
    delete_application_snackbar: "Die Bewerbung wurde erfolgreich gelöscht.",
    withdraw_application_snackbar:
      "Du hast deine Bewerbung erfolgreich zurückgezogen.",
    withdraw_application: "Bewerbung zurückziehen",
    withdraw_application_text:
      "Wenn du deine Bewerbung zurückziehst, wird sie aus Karriera entfernt, so dass die Recruiter keinen Zugriff mehr darauf haben. Bitte beachte, dass sie deine Daten möglicherweise bereits heruntergeladen haben. Bitte kontaktiere das Unternehmen direkt, um die Löschung deiner Daten aus dem System zu beantragen.",
  },

  // Candidate
  candidate: {
    applied_on: "Beworben am ",
    for_the_job: " auf die Stelle ",
    personal_information: "Persönliche Informationen",
    firstName: "Vorname",
    lastName: "Nachnahme",
    email: "E-Mail",
    phone: "Telefon",
    location: "Ort",
    country: "Land",
    documents: "Dokumente",
    additional_information: "Zusätzliche Informationen",
    show_additional_questions: "Zusätzliche Fragen anzeigen",
    hide_additional_questions: "Zusätzliche Fragen ausblenden",
    send_direct_message: "Sende jetzt eine Nachricht an ",
    send_message: "Nachricht senden",
    editing_note: "Notiz wird bearbeitet",
    reply: "Antworten",
    write_a_note: "Schreibe eine Notiz",
    collapse_all: "Alles einklappen",
    comments: "Kommentare",
    are_you_sure_delete_note:
      "Sind Sie sicher, dass Sie diese Notiz löschen wollen?",
    delete_note: "Notiz löschen",
    no_notes: "Es gibt keine Notizen",
    delete_message_snackbar_title: "Notiz erfolgreich gelöscht",
    no_documents: "Es gibt keine Dokumente",
    document_upload_success: "Das Dokument wurde erfolgreich hochgeladen",
    document_upload_error:
      "Beim Hochladen des Dokuments ist ein Fehler aufgetreten. Überprüfen Sie die Größe (max. 5 MB) und versuchen Sie es erneut.",
    activity_log: "Aktivitätsprotokoll",
    send_message_to_first_text: "hat Eine Nachricht an",
    send_message_to_second_text: "senden",
    received_message_from_first_text: "Eine Nachricht von",
    received_message_from_second_text: "erhalten",
    changed_status_from_first_text: "hat seinen Status von",
    changed_status_from_second_text: "geändert",
    changed_status_to: "auf",
    added_to_talent_pool:
      "wurde von {{jobTitle}} in den Talentpool {{talentPool}} verschoben",
    added_to_job_from_talent_pool:
      "wurde vom Talentpool {{talentPool}} von zum Job {{jobTitle}} hinzugefügt",
    added_note: "hat eine Notiz hinzugefügt",
    uploaded_document: "hat ein Dokument hochgeladen",
    answered_additional_questions_to_first_text: "hat zusätzliche Fragen zu",
    answered_additional_questions_to_second_text: "beantwortet",
    update_additional_questions_first_text: "Es wurden zusätzliche Fragen zu",
    update_additional_questions_second_text: "beantwortet",
    applied_to_first_text: "hat sich auf",
    applied_to_second_text: "beworben",
    on_date: "am",
    by_candidate: "von",
    addProfile: "Profil hinzufügen",
    additionalInfoTooltip: {
      firstStr: "Diese Daten sind auch für Bewerber sichtbar.",
      secondStr:
        "Möchtest du private Informationen hinzufügen, nutze die Notizen.",
    },
  },
  candidate_questions: {
    salary_expectation: "Wie hoch ist Ihre Gehaltsvorstellung?",
    earliest_start_date: "Wann ist Ihr frühester Starttermin?",
    current_professional_status: "Wie ist Ihr derzeitiger beruflicher Status?",
    highest_degree: "Welches ist Ihr höchster Abschluss?",
    driving_licenses: "Welche Führerscheine haben Sie?",
    language: "Welche Sprachkenntnisse haben Sie?",
    skills: "Welche Kenntnisse haben Sie?",
    no_answer: "Keine Antwort",
    tooltip_text: "Frage ist aktiv",
    editAdditionalInfo: "Zusätzliche Informationen bearbeiten",
  },

  // single job candidates
  single_job: {
    applications: "Bewerbungen",
    basic_job_boards: "Standard-Jobbörsen",
    premium_job_boards: "Premium-Jobbörsen",
    job_details: "Jobdetails",
    date_created: "Erstellt am",
    applied_on: "Beworben am",
    edit: "Bearbeiten",
    bookings: "Buchungen",
    in_status_for: "Im Status seit {{numberOfDaysInStatus}} Tag(en)",
  },

  // job details
  job_details: {
    job_description: "Stellenbeschreibung",
    contact: "Kontakt",
  },

  // basic job boards - table
  basic_job_board: {
    title: "Jobbörse",
    date_published: "Veröffentlicht am",
    published_at: "Veröffentlicht am",
    days_remaining: "Verbleibende Tage",
    company_profile_connect: "{{title}} verbinden",
    upload_header_image: "Bild hochladen",
    product_name: "Produktname",
    remaining: "Verbleibend",
    clicks: "Klicks",
    of: "von",
    days: "Tagen",
  },

  // add company profile dialog
  addCompanyProfileDialog: {
    title: "Verlinken Sie Ihr {{title}} Firmenprofil",
    description:
      "Um sicherzustellen, dass Ihre freien Stellen korrekt ausgeschrieben werden, geben Sie die URL Ihrer Seite auf {{title}} ein.",
  },

  // user settings menu
  userSettings: {
    user_settings: "Benutzereinstellungen",
    logout: "Abmelden",
    personal_information: "Persönliche Information",
    firstname: "Vorname",
    lastname: "Nachnahme",
    phone_number: "Telefonnummer",
    email: "E-Mail",
    language: "Sprache",
    german: "Deutsch",
    english: "Englisch",
    change_password: "Passwort ändern",
    current_password: "Aktuelles Passwort",
    new_password: "Neues Passwort",
    confirm_password: "Neues Password bestätigen",
    password_not_match: "Die Passwörter stimmen nicht überein.",
    upload_profile_picture: "Profilfoto hochladen",
    select_picture: "Bild auswählen",
    select_picture_formats: "Unterstützte Formate: JPG, PNG",
    incorrect_password: "Falsches Passwort",
    connect_account: "Verbinde deinen Account",
    snackbar_title: "Deine Einstellungen wurden erfolgreich gespeichert.",
    expected_dimensions: "Erwartete Abmessungen",
    or: "oder",
    min_width: "Mindestbreite",
    change_password_snackbar: "Dein Passwort wurde erfolgreich geändert.",
    no_password_snackbar:
      "Behalte deine Bewerbungen im Blick. Um den Login einfacher zu machen, lege ein Passwort fest.",
    create_password: "Passwort erstellen",
  },

  // team members
  team_members: {
    pending: "Ausstehend",
    joined: "Beigetreten",
    invite: "Einladen",
    invite_team: "Team einladen",
    invite_team_members: "Teammitglieder einladen",
    choose_team_members: "Teammitglieder auswählen",
    date_invited: "Eingeladen am",
    role: "Rolle",
    invite_title: "Teammitglieder einladen",
    email: "E-Mail",
    search_placeholder: "Suche nach E-Mail",
    invite_text:
      "Sie haben keine ausstehenden Einladungen an andere Teammitglieder",
    invite_button: "Jetzt einladen",
    delete_title: "Teammitglied jetzt entfernen",
    delete_text: "Möchten Sie dieses Mitglied aus Ihrem Team entfernen?",
    invalid_email: "Ungültige E-Mail Adresse",
    invite_snackbar_title: "Deine Einladung wurde erfolgreich versendet.",
    no_team_members_text:
      "Es gibt keine Teammitglieder, die eingeladen werden können",
    resend_invite: "Erneut einladen",
  },

  // job widget
  job_widget: {
    title: "Job Widget",
    embed_code: "Code einbetten",
    copy: "Kopieren",
  },

  // company page
  company_page: {
    employees: "Mitarbeiterinnen",
    open_position: "Alle offenen Positionen bei {{company_name}}",
    currently_open: "Wir haben aktuell {{total}} offene Positionen",
    departments: "Alle Berufsfelder",
    location: "Alle Orte",
    no_available_jobs: "Keine offenen Stellen",
    no_positions: "Im Moment haben wir keine offenen Stellen",
    show_all: "Alle anzeigen",
    other_open_positions: "Weitere offene Stellen",
    unknown: "Unbekannt",
    website: "Webseite",
    no_company_title:
      "Es scheint, dass die von Ihnen gesuchte Unternehmensseite derzeit inaktiv ist.",
    no_company_subtitle_one:
      "Wir entschuldigen uns für etwaige Unannehmlichkeiten. Wenn Sie glauben, dass dies ein Fehler ist,",
    no_company_subtitle_two:
      "oder wenn Sie weitere Informationen wünschen, wenden Sie sich bitte an unser Support-Team unter",
    no_company_thank_you: "Wir danken Ihnen für Ihr Verständnis.",
    back_to_homepage: "Zurück zur Homepage",
    more: "mehr",
    show_less: "Weniger anzeigen",
    show_more: "Mehr anzeigen",
  },

  // company settings
  company_settings: {
    title: "Unternehmenseinstellungen",
    company_name: "Unternehmensname",
    upload_logo: "Logo hochladen",
    technical_information: "Technische Informationen",
    api_token: "API Token",
    view: "Anzeigen",
    link_to_website: "Link zur Webseite",
    number_of_employees: "Anzahl der Mitarbeiter",
    industry: "Industrie",
    about_us: "Über uns",
    copy_message: "in Zwischenablage kopiert",
    preview_company_page: "Vorschau Karriereseite",
    switch_text_on: "Ihre Karriereseite ist sichtbar für Bewerber",
    switch_text_off: "Ihre Karriereseite ist nicht sichtbar für Bewerber",
    career_page: "Karriereseite",
    snackbar_title: "Deine Einstellungen wurden erfolgreich gespeichert.",
    link_company_profile: "Link Unternehmensprofil ",
    company_color: "Unternehmensfarbe",
    color: "Farbe",
  },

  login: {
    with_karierra: "Mit Karriera",
    your_design: "optimierte Stellenanzeigen gestalten",
    reach_more: "mehr Kandidatinnen erreichen",
    hire: "kollaborativ arbeiten",
    welcome_to_karriera: "Willkommen bei Karriera",
    please_login: "Bitte melde dich an",
    e_mail: "E-Mail",
    e_mail_example: "name@example.de",
    password: "Passwort",
    your_password: "Dein Passwort",
    forgot_password: "Passwort vergessen?",
    log_in: "Anmelden",
    you_dont_have_acc: "Du hast keinen Account? ",
    sign_up: "Jetzt anmelden",
    password_changed_title: "Ihr Passwort wurde erfolgreich geändert",
    password_changed_text:
      "Bitte logge Dich in Dein Konto ein, indem Du die untenstehende Schaltfläche benutzt.",
    sign_in: "Jetzt anmelden",
  },

  // Login page, register
  register: {
    register_as_a_company: "Unternehmen kostenlos anmelden",
    set_your_company_profile: "Jetzt Unternehmensprofil erstellen",
    placeholder_company_name: "Example GmbH",
    placeholder_company_website: "unternehmen.de",
    company_name: "Name des Unternehmens",
    company_website: "Webseite des Unternehmens",
    message: "Feld ist erforderlich",
    industry: "Branche",
    select_industry: "Branche auswählen",
    number_of_employees: "Anzahl der Mitarbeiter",
    select_number_of_employees: "Mitarbeiteranzahl auswählen",
    continue: "Weiter",
    alredy_have_account: "Du hast schon einen Account? ",
  },

  recruiter: {
    register_as_recruiter: "Als Recruiter kostenlos anmelden",
    set_your_recruiter_profile: "Jetzt Recruiterprofil erstellen",
    first_name: "Vorname",
    last_name: "Nachnahme",
    phone_number: "Telefonnummer",
    email_placeholder: "example@company.de",
    privacy_policy_1: "Ich erkläre mich mit den ",
    t_and_c: "AGB",
    privacy_policy_2:
      " und der Vereinbarung zur Datenverarbeitung einverstanden und habe die ",
    privacy: "Datenschutzbestimmungen",
    read: " gelesen",
    finish: "Fertig",
  },

  // apply change
  apply_page: {
    password: "Passwort",
    forgot_password: "Passwort vergessen?",
    login: "Anmelden",
    send_magic_link: "Sende Magic Link",
    error_message:
      "Es ist bereits ein Account mit dieser E-Mail registriert. Bitte melde dich an, bevor du dich bewirbst.",
    apply_now: "Jetzt bewerben",
    upload_cv: "Lebenslauf hochladen",
    terms_first_text: "Ich erkläre mich mit den",
    terms_second_text: "AGB",
    terms_third_text:
      "und der Vereinbarung zur Datenverarbeitung einverstanden und habe die",
    terms_forth_text: "Datenschutzbestimmungen",
    terms_fifth_text: "gelesen.",
    apply_text:
      "Füllen Sie die nachstehenden Felder aus und bewerben Sie sich jetzt",
    no_job_text: "Dieser Job ist nicht mehr verfügbar.",
    share_job: "Teile diesen Job",
  },

  // candidate dashboard
  candidate_dashboard: {
    subtitle: "Du hast dich auf folgende Jobs beworben ",
    show_job: "Stelle anzeigen",
    open_drafts: "Entwürfe öffnen",
    no_jobs: "Du hast dich auf keine aktuelle Position beworben.",
  },

  // reset password page (request reset password)
  reset_password_page: {
    title: "Passwort zurücksetzen",
    text: "Bitte gib Deine E-Mail-Adresse unten ein",
    button: "Link senden",
    link: "Zurück zur Webseite",
    check_inbox: "Prüfe Deinen Posteingang",
    email_not_exist: "Diese E-Mail existiert nicht im System",
    email_exist_text:
      "Wenn mit der angegebenen E-Mail-Adresse ein Konto verknüpft ist, senden",
    email_exist_second_text:
      "wir Dir eine E-Mail mit Anweisungen zum Zurücksetzen des Passwortes.",
    check_spam: "Vergiss nicht den Spam-Ordner zu überprüfen.",
    new_password_text: "Bitte bestätige Dein neues Passwort",
    new_password: "Neues Passwort",
    confirm_password: "Passwort bestätigen",
  },

  // limit dialog
  limit_dialog: {
    title: "Finde einen passenden Plan für dein Unternehmen",
    text: "Möchtest du deinen Prozess individualisieren? Wir können dein Karriera-Konto perfekt auf deine Bedürfnisse anpassen.",
    button: "Jetzt anfragen",
    confirmation_title: "Deine Anfrage wurde versendet",
    confirmation_text:
      "Vielen Dank, dass Sie sich mit uns in Verbindung gesetzt haben! Jemand aus unserem Team wird sich in Kürze mit Ihnen in Verbindung setzen.",
  },

  // archive job dialog
  archive_job_dialog: {
    title: "Sind Sie sicher, dass Sie Ihren Job archivieren wollen?",
    t1: "Die Stelle wird nicht mehr auf Ihrer Karriereseite oder Ihrem Job-Widget zu sehen sein",
    t2: "Die kostenlose Mehrfachveröffentlichung wird innerhalb der nächsten 12 Stunden deaktiviert",
    t3: "Bewerber können keine neuen Bewerbungen mehr einreichen",
    t4: "Produkte mit einer Restlaufzeit verfallen und können für diese Stelle nicht wieder reaktiviert werden",
    t5: "Sie können jedoch weiterhin Kandidatinnen verwalten, die sich bereits beworben haben",
    t6: "Sie können diese Stelle später jederzeit reaktivieren, um weitere Bewerbungen zu erhalten",
    activate_title: "Sind Sie sicher, dass Sie die Stelle reaktivieren wollen?",
    t7: "Die kostenlose Veröffentlichung wird innerhalb der nächsten 12 Stunden reaktiviert",
    t8: "Der Job wird auf Ihrer Karriereseite und im Job-Widget angezeigt",
    cancel: "Den Job online lassen",
    delete: "Diesen Job archivieren",
  },

  delete_job_dialog: {
    title: "Bist du sicher, dass du dieser Job enggültig löschen möchtest?",
    body: "Der Job hat aktuell den Status archiviert und kann jederzeit wieder aktiviert werden. Wird der Job endgültig gelöscht, kann dieser Vorgang nicht mehr rückgängig gemacht werden.",
    cancel: "Abbrechen",
    delete: "Job löschen",
  },

  // magic link confirmation page
  magic_link_confirmation_page: {
    title: "Prüfe Deinen Posteingang",
    text1: "Wenn die angegebene E-Mail-Adresse mit einem Konto verknüpft ist,",
    text2:
      "senden wir Dir eine E-Mail mit Anweisungen, wie Du dich anmelden und bewerben kannst.",
    text3: "Vergiss nicht, Deinen Spam-Ordner zu überprüfen.",
    back_to_job: "Zurück zur Stellenanzeige",
  },

  // messages page
  messages_page: {
    no_messages: "Du hast noch keine Nachrichten.",
    applied_to_job_one: "Beworben auf",
    on: "am",
    show_job_details: "Jobdetails zeigen",
    at: "um",
    delete_message_dialog_title: "Nachricht löschen",
    delete_message_dialog_text:
      "Bist du sicher, dass du die Nachricht löschen möchtest?",
    new_message_placeholder: "Nachricht schreiben",
    edit_dialog_title: "Änderungen an der Nachricht speichern",
    edit_dialog_text:
      "Möchten Sie die vorgenommenen Änderungen speichern und fortfahren?",
    discard_dialog_title: "Änderungen an der Nachricht verwerfen",
    discard_dialog_text:
      "Möchten Sie die vorgenommenen Änderungen verwerfen und fortfahren?",
    edited: "Bearbeitet",
    new_chat: "Neue Nachricht",
    new_conversation: "Neuer Chat",
    delete_message_snackbar_title: "Nachricht erfolgreich gelöscht",
    show_profile: "Profil anzeigen",
    mark_as_read: "Als gelesen markieren",
    mark_as_unread: "Als ungelesen markieren",
    from: "Aus",
  },

  // statuses
  status: {
    new: "Neu",
    in_progress: "Noch offen",
    review: "Noch offen",
    hired: "Angestellt",
    rejected: "Ablehnen",
    active: "Aktiv",
    inactive: "Inaktiv",
    archived: "Archiviert",
    draft: "Entwurf",
  },

  // customers
  customers: {
    company: "Unternehmen",
    invite: "Kunden einladen",
    no_customers_title: "Die Liste ist leer, Sie haben noch keine Kunden.",
    no_customers_subtitle_one: `Über die Schaltfläche "Kunden einladen" kannst du deine Kunden einladen.`,
    no_customers_subtitle_two: `Wenn du bereits Kunden hast und sie hier synchronisieren möchtest, klicke bitte auf die Schaltfläche "Kunden synchronisieren".`,
    no_customers_sync: "Kundenliste synchronisieren",
    no_customers_invite: "Kunden einladen",
    sync: "Kunden synchronisieren",
    customer: "Kunde",
    select_customer: "Kunden auswählen",
    select_customer_subtitle:
      "Wähle einen Kunden, für den ein Job erstellt werden soll.",
    no_selected_customer: "Bitte wähle einen Kunden aus, um fortzufahren.",
    connected_with: "Verknüpft mit",
    since: "seit",
    disconnect: "Trennen",
    company_name: "Name des Unternehmens",
    contact_person_firstname: "Vorname des Ansprechpartners",
    contact_person_lastname: "Nachname des Ansprechpartners",
    contact_email: "E-Mail der Kontaktperson",
    contact_phone: "Telefon der Kontaktperson",
    disconnect_dialog_title: "Verbindung trennen",
    disconnect_dialog_body:
      "Bist du sicher, dass du die Verbindung aufheben willst?",
    invite_snackbar_success:
      "Die Einladung wurde erfolgreich an den Kunden versendet.",
    import_customer: "Kunden importieren",
    import: "Importieren",
    selected: "ausgewählt",
    import_success_snackbar: "Dein Import war erfolgreich.",
    no_customers_dialog_title:
      "Du hast keine Kunden, die du importieren kannst.",
  },

  // floating menu - kanban board
  floating_menu: {
    change_status: "Status ändern",
    deselect_all: "Abbrechen",
  },

  talentPools: {
    created_at: "Erstellt am",
    in_the_talentpool: "im Talentpool",
    talentPools: "Talentpools",
    noTalentPoolsTitle: "Du hast noch keinen Talentpool.",
    noTalentPoolsSubtitle:
      "Erstelle deinen ersten Talentpool und speichere passende Profile für später.",
    createTalentPool: "Talentpool erstellen",
    addTalentPool: "Talentpool hinzufügen",
    description: "Beschreibung",
    date: "Datum",
    created: "Erstellt",
    profiles: "Profile",
    delete: "Löschen",
    editTalentPool: "Talentpool bearbeiten",
    deleteTalentPoolTitle: "Talentpool löschen",
    deleteTalentPoolFirstSubTitle:
      "Bist du sicher, dass du diesen Talentpool löschen möchtest?",
    deleteTalentPoolSecondSubTitle:
      "Der Talentpool wird mit allen darin gespeicherten Profilen entfernt.",
    successTalentPoolCreated: "Der Talentpool wurde erfolgreich erstellt",
    successTalentPoolDeleted: "Der Talentpool wurde erfolgreich gelöscht",
    successTalentPoolEdited: "Der Talentpool wurde erfolgreich bearbeitet",
    successAddCandidateToJob:
      "Das Profil wurde erfolgreich zur Stelle hinzugefügt und aus dem Talentpool entfernt",
    successCandidateAddedInTalentPool:
      "Das Profil wurde erfolgreich zum Talentpool hinzugefügt",
    successCandidatesAddedInTalentPool:
      "Die Profile wurden erfolgreich zum Talentpool hinzugefügt",
    isInYourTalentPool: "ist in deinem Talentpool",
    add: "Hinzufügen",
    addToTalentPool: "Zu Talentpool hinzufügen",
    addToActiveJob: "Zum Job hinzufügen",
    addProfileToJob:
      "Profile {{firstname}} {{lastname}} zu einer Stelle hinzufügen",
    deleteCandidateFromTalentPoolTitle: "Profil aus Talentpool löschen",
    deleteCandidateFromTalentPoolSubTitle:
      "Bist du sicher, dass du dieses Profil aus dem Talentpool löschen möchtest?",
    noCandidatesInTalentPoolTitle:
      "Deine Liste ist leer, du hast noch keine Profile hinzugefügt.",
    noCandidatesInTalentPoolSubTitle:
      "Du kannst Profile, die für deine offenen Positionen gerade nicht passen, zu diesem Talentpool hinzufügen. Gehe dafür in die Bewerberliste eines Jobs und klicke bei der Bewerbung auf den Statuus und wähle: Zum Talentpool hinzufügen",
  },

  // universal
  active: "Aktiv",
  archived: "Archiviert",
  draft: "Entwurf",
  field_max_length_text: "Die Textlänge darf 200 Zeichen nicht überschreiten",
  expired: "Ausgelaufen",
  aborted: "Abgebrochen",
  type: "Typ",
  terms: "AGB",
  privacy: "Datenschutz",
  no_options: "Es konnte nichts gefunden werden.",
  resend_link: "Link erneut senden",
  email: "E-Mail",
  valid_email_message: "Bitte geben Sie eine gültige E-Mail-Adresse an",
  invalid_email_message: "Ungültige Email-Adresse",
  website_valid_url_message: "Bitte gib eine gültige URL an",
  password_match_message: "Die Passwörter stimmen nicht überein.",
  accept_terms_text:
    "Bitte akzeptiere die Allgemeinen Geschäftsbedingungen und die Datenschutzerklärung",
  email_exist: "E-Mail existiert",
  company_name_exist: "Unternehmensname vorhanden",
  application_email_exist:
    "Ein Bewerbung mit dieser E-Mail ist übermittelt worden",
  password_match_message_try_again:
    "Die E-Mail und das Passwort stimmen nicht überein. Bitte versuchen Sie es erneut.",
  cv_text:
    "Die Option “Lebenslauf hochladen” ist nicht verpflichtend, wird aber auf der Bewerbungsseite immer angeboten",
  yes: "Ja",
  no: "Nein",
  comming_soon: "Demnächst",
  at: "um",
  delete_document: "Dokument löschen",
  document_dialog:
    "Möchtest du das Dokument {{name}} wirklich löschen? Dieser Vorgang kann nicht mehr rückgängig gemacht werden.",
  showing: "Zeige",
  results: "Ergebnissen",
  jobDetails: {
    showMoreBtn: "Mehr anzeigen",
    showLessBtn: "Weniger anzeigen",
  },
  singleJob: {
    jobDetails: {
      titles: {
        jobMedia: "Jobmedien",
        jobDescription: "Stellenbeschreibung",
        jobDetails: "Jobdetails",
        applicationProcess: "Bewerbungsprozess",
        requiredDocuments: "Erforderliche Dokumente",
        additionalQuestions: "Zusätzliche Fragen",
        noAdditionalQuestionsTitle: "Sie haben noch keine zusätzliche Frage.",
        noAdditionalQuestionsSubtitle:
          "Klicken Sie auf Bearbeiten, um eine Frage für potenzielle Bewerberinnen hinzuzufügen.",
      },
      salary: {
        currencies: {
          "(EUR)": "Euro (EUR)",
          "(USD)": "U.S. Dollar (USD)",
          "(CHF)": "Schweizer Franken  (CHF)",
          "(GBP)": "Pfund Sterling (GBP)",
        },
        type: {
          annually: "Jährlich",
          monthly: "Monatlich",
          hourly: "Stündlich",
        },
      },
    },
    errors: {
      maxSalaryGreaterThan:
        "Der Höchstlohn muss höher sein als der Mindestlohn",
    },
  },
  jobPreview: {
    salary: "{{min}} {{currency}} bis {{max}} {{currency}}",
  },

  workflow: {
    addWorkflow: "Workflow hinzufügen",
    myWorkflows: "Meine Workflows",
    adjustWorkflow: "Workflow anpassen",
    statusOptions: "Statusoptionen",
    addNewState: "Status hinzufügen",
    newState: "Neuer Status",
    defaultWorkflowText: "Setzen Sie diesen Workflow als Standard",
    workflowCreatedSnackbar: "Workflow erfolgreich erstellt",
    workflowUpdatedSnackbar: "Workflow erfolgreich bearbeitet",
    workflowDeletedSnackbar: "Workflow erfolgreich gelöscht",
    deleteWorkflow: "Workflow löschen",
    deleteWorkflowText:
      "Bist du sicher, dass du diesen Workflow löschen möchtest?",
    defaultWorkflow: "Standart Workflow",
    setWorkflow: "Workflow auswählen",
    setDefaultWorkflowForJob:
      "Diesen Workflow als Standart für diesen Job festlegen",
    deleteWorkflowDialogNote:
      "Wenn du diesen Status löschst, werden alle Bewerbungen in diesem Status zum Status Neu hinzugefügt",
  },

  analytics: {
    applications: "Anwendungen",
    numberOfApplications: "Annotate the candidates",
    sourceOfHire: "Bewusst werden",
    thisWeek: "Diese Woche",
    lastWeek: "Letzte Woche",
    last7Days: "Letzte 7 Tage",
    thisMonth: "Dieser Monat",
    lastMonth: "Letzter Monat",
    selectDateRange: "Datumsbereich auswählen",
    unknown: "Unbekannt",
    noDataText: "Es sind keine Daten verfügbar.",
    changeDateFilter: "Versuchen Sie, die Datumsbereiche zu ändern",
  },
};
