import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import {
  Stack,
  Button,
  Popover,
  Divider,
  Typography,
  MenuItem
} from "@mui/material";
import iconAndText from "../DataGrid_new/cellRenderers/iconAndText";
import { ITalentPoolPopoverComponent } from "../../models/TalentPools";

const TalentPoolPopover = ({
  t,
  id,
  onCloseTalentPoolMenu,
  open,
  anchorEl,
  jobId,
  talentPools,
  currentApplication,
  params,
  onTalentPoolHoverLeave,
  onClickTalentPoolMenuItem,
  onOpenCreateTalentPoolDialog
}: ITalentPoolPopoverComponent) => (
  <OutsideClickHandler onOutsideClick={onCloseTalentPoolMenu}>
    <Popover
      data-testid={"talent-pool-menu-wrapper"}
      className="talentpool-menu"
      id={id}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: jobId ? "right" : "left"
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: jobId ? "left" : "right"
      }}
      sx={{
        marginLeft: jobId ? 0.15 : -0.15
      }}
    >
      <Stack
        className="applicants-list-dialog"
        maxHeight="136px !important"
        overflow="auto"
        onMouseOver={() => onTalentPoolHoverLeave(true)}
        onMouseLeave={() => onTalentPoolHoverLeave(false)}
      >
        {talentPools && talentPools?.length > 0 ? (
          talentPools.map((talentPool: any) => (
            <MenuItem
              data-testid={`talent-pool-existing-tp-button-${talentPool.id}`}
              sx={{
                height: 30.48
              }}
              key={talentPool?.id}
              onClick={() =>
                onClickTalentPoolMenuItem(
                  talentPool.id,
                  params?.data || currentApplication
                )
              }
            >
              {iconAndText({
                text: talentPool?.title,
                variant: "dot",
                iconColor: talentPool?.color,
                src: talentPool?.color
              })}
            </MenuItem>
          ))
        ) : (
          <Stack alignItems="center" p={1.5}>
            <Typography variant="subtitle2" color="textSecondary">
              {t("talentPools.noTalentPoolsTitle")}
            </Typography>
          </Stack>
        )}
      </Stack>
      <Divider />
      <Stack alignItems="center" p={1.5}>
        <Button
          data-testid={"talent-pool-create-tp-button"}
          variant="contained"
          onClick={onOpenCreateTalentPoolDialog}
          sx={{ maxHeight: 25 }}
        >
          {t("talentPools.createTalentPool")}
        </Button>
      </Stack>
    </Popover>
  </OutsideClickHandler>
);

export default TalentPoolPopover;
