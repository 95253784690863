import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas";
import Teams from "./reducers/Team";
import JobList from "./reducers/JobList";
import JobEditor from "./reducers/JobEditor";
import SingleJob from "./reducers/SingleJob";
import Applications from "./reducers/Applications";
import Auth from "./reducers/Auth";
import Messages from "./reducers/Messages";
import CompanySettings from "./reducers/CompanySettings";
import Registration from "./reducers/Registration";
import Candidate from "./reducers/Candidate";
import CurrentUser from "./reducers/CurrentUser";
import TopHeader from "./reducers/TopHeader";
import JobPreview from "./reducers/JobPreview";
import CompanyInfo from "./reducers/CompanyInfo";
import TalentPools from "./reducers/TalentPools";
import Customers from "./reducers/Customers";
import Dialogs from "./reducers/dialogs";
import Workflow from "./reducers/Workflow";
import Analytics from "./reducers/Analytics";
import { globalNotificationSlice } from "../slices/NotificationSlice/GlobalNotificationSlice";
import { candidateSlice } from "../slices/Candidate";
import { candidatesSlice } from "../slices/Candidates/candidatesSlice";
import { companySlice } from "../slices/Company/companySlice";
import { dashboardSlice } from "../slices/Dashboard/dashboardSlice";
import { jobSlice } from "../slices/Job/jobSlice";
import { navigationSlice } from "../slices/Navigation/navigationSlice";
import { userSlice } from "../slices/User/userSlices";

declare global {
  interface Window {
    __PRELOADED_STATE__: any;
    snapSaveState: any;
  }
}

const rootReducer = combineReducers({
  applications: Applications,
  auth: Auth,
  analytics: Analytics,
  candidate: candidateSlice.reducer, // TODO REFACTOR
  candidate_new: Candidate,
  candidates: candidatesSlice.reducer, // TODO REFACTOR
  company: companySlice.reducer, // TODO REFACTOR
  companyInfo: CompanyInfo,
  companySettings: CompanySettings,
  currentUser: CurrentUser,
  customers: Customers,
  dashboard: dashboardSlice.reducer, // TODO REFACTOR
  dialogs: Dialogs,
  globalNotifications: globalNotificationSlice.reducer, // TODO REFACTOR
  job: jobSlice.reducer, // TODO REFACTOR
  jobEditor: JobEditor,
  jobListNew: JobList,
  jobPreview: JobPreview,
  messages: Messages,
  navigation: navigationSlice.reducer, // TODO REFACTOR
  registration: Registration,
  singleJob: SingleJob,
  teams: Teams,
  topHeader: TopHeader,
  user: userSlice.reducer,
  talentPools: TalentPools,
  workflow: Workflow,
});

const preloadedState = window.__PRELOADED_STATE__;
delete window.__PRELOADED_STATE__;

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
  preloadedState,
});

window.snapSaveState = () => ({
  __PRELOADED_STATE__: store.getState(),
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
