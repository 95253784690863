import React, { useEffect, useMemo, useRef, useState } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import { AgGridReact } from "ag-grid-react";
import { IDataGrid } from "../../models/DataGrid";
import getColDefs, { defaultColDef } from "./config";

const DataGrid = ({
  list,
  type,
  actions,
  t,
  onSelect,
  onRowClick,
  headerHeight,
  rowHeight
}: IDataGrid) => {
  const gridRef = useRef<AgGridReact>(null);
  const gridContainerRef = useRef<HTMLDivElement>(null);
  const [rowData, setRowData] = useState(list);

  const getRowHeight = () => {
    if (rowHeight) return rowHeight;
    if (type === "talentPools") return 80;
    const rowMinHeight = 48;
    const containerHeight = gridContainerRef.current?.offsetHeight;
    const currentRowHeight = containerHeight
      ? (containerHeight - 2) / 11
      : rowMinHeight;

    return currentRowHeight > rowMinHeight ? currentRowHeight : rowMinHeight;
  };

  const handleOnGridReady = () => {
    if (gridContainerRef.current)
      gridContainerRef.current.style.visibility = "visible";
  };

  useEffect(() => {
    setRowData(list);
  }, [list]);

  const columnDefs = useMemo(
    () => getColDefs({ type, actions, t, onSelect }),
    [type]
  );

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        visibility: "hidden"
      }}
      ref={gridContainerRef}
    >
      <div
        className="ag-theme-material"
        style={{ height: "100%", width: "100%" }}
        data-testid={"data-grid-container"}
      >
        <AgGridReact
          headerHeight={headerHeight}
          onGridReady={handleOnGridReady}
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          getRowHeight={getRowHeight}
          onRowClicked={onRowClick ? (row) => onRowClick(row.data) : undefined}
          enableCellTextSelection
          suppressCellFocus
        ></AgGridReact>
      </div>
    </div>
  );
};

export default DataGrid;
