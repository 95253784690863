import React, { useState, ChangeEvent } from "react";
import {
  Dialog,
  Typography,
  Stack,
  Box,
  TextField,
  Button,
  Divider
} from "@mui/material";
import { useParams } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import CVSection from "../../../pages/JobPreview/JobAction/CVSection";
import { IApplyJobDialog } from "../../../models/Team";
import {
  jobActionAuthFormFields,
  jobActionRegisterFormDefaultValues
} from "../../../pages/JobPreview/JobAction/config";
import { getJobPreviewState } from "../../../store_new/selectors/JobPreview";
import { TJobActionRegisterFormFields } from "../../../models/JobPreview";
import {
  applyToJob,
  checkCandidateExist,
  resetApplyError,
  sendMagicLink
} from "../../../store_new/reducers/JobPreview";
import { StyledLoadingApplyButton } from "../../../pages/JobPreview/styles";
import { getJobListSelectedJobs } from "../../../store_new/selectors/JobList";
import { getSingleJobState } from "../../../store_new/selectors/SingleJob";
import { fetchJobApplications } from "../../../store_new/reducers/SingleJob";

const ApplyJobDialog = ({ isOpen, onCancel }: IApplyJobDialog) => {
  const dispatch = useDispatch();
  const { key } = useParams();
  const { t } = useTranslation();
  const selectedJobs = useSelector(getJobListSelectedJobs);
  const { jobDetails, jobLocations } = useSelector(getSingleJobState);
  const { isApplyInProgress, companyColor } = useSelector(getJobPreviewState);

  const jobCity = jobLocations ? jobLocations[0].city : "";
  const jobUrlKey = key ? jobDetails?.url_key : selectedJobs[0]?.url_key;

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors }
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(jobActionAuthFormFields(t)),
    defaultValues: {
      ...jobActionRegisterFormDefaultValues,
      agreement: true
    }
  });
  const [uploadedCV, setUploadedCV] = useState<FileList | null>(null);

  const handleOnUploadCV = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.[0]) setUploadedCV(event.target.files);
  };
  const handleOnResetCV = (event: any) => {
    setUploadedCV(null);
    event.preventDefault();
  };

  const onClose = () => {
    if (key) {
      dispatch(
        fetchJobApplications({
          jobId: key,
          isApplyDialog: true,
          callback: () => "fetchJobApplications"
        })
      );
    }
    onCancel();
    resetApplyError();
    reset();
  };

  const onCandidateExist = () => {
    dispatch(
      sendMagicLink({
        urlKey: jobUrlKey as string,
        email: getValues().email,
        callback: () => onClose()
      })
    );
  };

  const onCandidateNotExist = (body: any) => {
    dispatch(
      applyToJob({
        jobUrlKey: jobUrlKey as string,
        body,
        callback: () => onClose()
      })
    );
  };

  const onSubmit = (formFields: TJobActionRegisterFormFields) => {
    const body = { ...formFields, files: uploadedCV };

    dispatch(
      checkCandidateExist({
        email: getValues().email,
        callback: (type) => {
          if (type === "error") {
            onCandidateExist();
          } else {
            onCandidateNotExist(body);
          }
        }
      })
    );
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <Stack
        p={3}
        spacing={3}
        sx={{ backgroundColor: "#fff" }}
        data-testid={"jobs-list-apply-job-dialog"}
      >
        <Stack direction="row" justifyContent="space-between">
          <Stack>
            <Typography variant="body1" fontWeight={500}>
              {t("joblist.add")}
            </Typography>
          </Stack>
          <Stack>
            <XMarkIcon
              onClick={onClose}
              width={24}
              height={24}
              stroke="#131E30"
              style={{ cursor: "pointer" }}
            />
          </Stack>
        </Stack>
        <Divider
          sx={{
            width: "100%",
            borderColor: "#E5E7EB",
            my: 3
          }}
        />
        <Stack spacing={1.5}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="subtitle2" color="#000000" fontWeight={500}>
              {t("create_job_first_step.job_title")}
            </Typography>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              maxWidth="75%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {key ? jobDetails?.title : selectedJobs[0]?.title}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="subtitle2" color="#000000" fontWeight={500}>
              {t("joblist.location_placeholder")}
            </Typography>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              maxWidth="75%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {key ? jobCity : selectedJobs[0]?.locations[0]?.city}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="subtitle2" color="#000000" fontWeight={500}>
              {t("create_job_second_step.job_type")}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              {key ? jobDetails?.contract_type : selectedJobs[0]?.contract_type}
            </Typography>
          </Stack>
        </Stack>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            {...register("email")}
            placeholder="name@example.de"
            fullWidth
            error={Boolean(errors.email)}
            helperText={errors.email?.message}
          />

          <Box mt={1.5}>
            <TextField
              placeholder={t("userSettings.firstname") as string}
              fullWidth
              {...register("firstname")}
              error={Boolean(errors["firstname"])}
              helperText={errors["firstname"]?.message}
            />
          </Box>
          <Box mt={1.5}>
            <TextField
              placeholder={t("userSettings.lastname") as string}
              fullWidth
              {...register("lastname")}
              error={Boolean(errors["lastname"])}
              helperText={errors["lastname"]?.message}
            />
          </Box>
          <CVSection
            t={t}
            uploadedCV={uploadedCV?.[0]}
            onUploadCV={handleOnUploadCV}
            onResetCV={handleOnResetCV}
          />

          <Stack
            mt={1.5}
            direction="row"
            spacing={1.5}
            justifyContent="flex-end"
          >
            <Button variant="outlined" onClick={onClose}>
              {t("button.secondCancel")}
            </Button>
            <StyledLoadingApplyButton
              sx={{ minWidth: 134 }}
              type={"submit"}
              variant={"contained"}
              loading={isApplyInProgress}
              companyColor={companyColor}
              loadingPosition="end"
            >
              {t("button.save")}
            </StyledLoadingApplyButton>
          </Stack>
        </form>
      </Stack>
    </Dialog>
  );
};

export default ApplyJobDialog;
