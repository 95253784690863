import React from "react";
import {
  ArrowDownTrayIcon,
  EyeIcon,
  PencilSquareIcon,
  PlusCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Stack, Typography } from "@mui/material";
import { IApplicationDocumentsComponent } from "../../../models/ApplicationPage";
import {
  StyledCandidateContentContainer,
  StyledCandidateContentRow,
  StyledCandidateContentRowTitleContainer,
  StyledCandidateContentTitleContainer,
  StyledCandidateContentValue,
  StyledDivider,
  StyledIconWrapper,
  StyledNoDocumentsContainer,
} from "../styles";

const DocumentsComponent = ({
  t,
  isCandidate,
  documents,
  desktop,
  onUpload,
  onDownload,
  onDelete,
}: IApplicationDocumentsComponent) => (
  <StyledCandidateContentContainer
    spacing={2}
    minHeight={desktop ? "100% !important" : "250px !important"}
  >
    <StyledCandidateContentTitleContainer direction={"row"}>
      <Typography
        fontSize={18}
        color={"#131E30"}
        fontWeight={500}
        lineHeight={"18px"}
      >
        {t("candidate.documents")}
      </Typography>
      {isCandidate && documents && documents.length < 4 && (
        <Stack
          component={"label"}
          sx={{ marginLeft: "auto", cursor: "pointer" }}
        >
          <PlusCircleIcon width={24} height={24} />
          <input
            hidden
            type="file"
            onChange={(event) => {
              onUpload({ event, type: "documents" });
            }}
          />
        </Stack>
      )}
    </StyledCandidateContentTitleContainer>
    <StyledDivider />
    {!documents?.length && (
      <StyledNoDocumentsContainer spacing={1}>
        <EyeIcon width={24} height={24} stroke="#818CF8" />
        <Typography color="textSecondary" variant="subtitle2">
          {t("candidate.no_documents")}
        </Typography>
      </StyledNoDocumentsContainer>
    )}
    {documents?.map((doc) => (
      <StyledCandidateContentRow
        direction={"row"}
        key={doc.id}
        desktop={desktop}
        document
      >
        <StyledCandidateContentRowTitleContainer desktop={desktop} document>
          <span className={"filename"}>
            {doc.filename?.split("/")?.pop()?.split("_")?.pop()}
          </span>
        </StyledCandidateContentRowTitleContainer>

        <StyledCandidateContentValue direction={"row"} spacing={1.5}>
          <StyledIconWrapper>
            <a href={doc.filename} target="_blank" rel="noreferrer">
              <EyeIcon className={"eye-icon"} />
            </a>
          </StyledIconWrapper>
          <StyledIconWrapper>
            <ArrowDownTrayIcon
              onClick={() => {
                onDownload(doc);
              }}
              className={"download-icon"}
            />
          </StyledIconWrapper>
          {isCandidate && (
            <StyledIconWrapper>
              {doc.is_cv ? (
                <Stack component="label">
                  <PencilSquareIcon className={"cv-icon"} />
                  <input
                    hidden
                    type="file"
                    onChange={(event) => {
                      onUpload({ event, type: "cv" });
                    }}
                  />
                </Stack>
              ) : (
                <XMarkIcon
                  className={"delete-icon"}
                  onClick={() => {
                    onDelete(doc);
                  }}
                />
              )}
            </StyledIconWrapper>
          )}
        </StyledCandidateContentValue>
      </StyledCandidateContentRow>
    ))}
  </StyledCandidateContentContainer>
);

export default DocumentsComponent;
