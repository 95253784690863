import React from "react";
import {
  Stack,
  Button,
  Typography,
  Divider,
  Menu,
  MenuItem,
} from "@mui/material";
import Workflow from "./Workflow";
import Loading from "../../components/Loading";
import DeleteDialog from "../../components/dialogs/DeleteDialog";
import { IWorkflowComponent } from "../../models/Workflow";

const WorkflowComponent = ({
  t,
  isListLoading,
  workflows,
  activeWorkflowId,
  anchorEl,
  isDeleteDialogOpen,
  editWorkflow,
  onOpenCloseDeleteDialog,
  onDeleteWorkflow,
  onSetEditWorkflow,
  onOpenWorkflowMenu,
  onCloseWorkflowMenu,
  onOpenCreateWorkFlowDialog,
  onSetActiveWorkflowId,
}: IWorkflowComponent) => {
  return (
    <Stack mt={3}>
      <Stack width="max-content" alignSelf="flex-end">
        <Button
          onClick={() => onOpenCreateWorkFlowDialog()}
          variant="contained"
          onMouseOver={() => onSetEditWorkflow(null)}
        >
          {t("workflow.addWorkflow")}
        </Button>
      </Stack>

      <Stack
        mt={3}
        p={3}
        border="1px solid #E5E7EB"
        borderRadius={1.5}
        sx={{ backgroundColor: "#fff" }}
        spacing={3}
      >
        <Typography fontWeight={500}>{t("workflow.myWorkflows")}</Typography>
        <Divider sx={{ borderColor: "#E5E7EB" }} />
        {isListLoading ? (
          <Loading />
        ) : (
          <Stack spacing={1.5} minHeight="50vh">
            {workflows.map((workflow) => (
              <Workflow
                key={workflow.id}
                workflow={workflow}
                activeWorkflowId={activeWorkflowId}
                onSetEditWorkflow={onSetEditWorkflow}
                onSetActiveWorkflowId={onSetActiveWorkflowId}
                onOpenWorkflowMenu={onOpenWorkflowMenu}
              />
            ))}
          </Stack>
        )}
      </Stack>
      <Menu
        id="workflows-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onCloseWorkflowMenu}
        MenuListProps={{
          "aria-labelledby": "actions-button",
        }}
      >
        <MenuItem
          sx={{
            height: 30.48,
          }}
          onClick={() => onOpenCreateWorkFlowDialog()}
        >
          {t("single_job.edit")}
        </MenuItem>
        {editWorkflow?.id != workflows[0].id && (
          <MenuItem
            sx={{
              height: 30.48,
            }}
            onClick={() => onOpenCloseDeleteDialog(true)}
          >
            {t("talentPools.delete")}
          </MenuItem>
        )}
      </Menu>
      <DeleteDialog
        t={t}
        isOpen={isDeleteDialogOpen}
        title={t("workflow.deleteWorkflow")}
        body={t("workflow.deleteWorkflowText")}
        onCancel={() => onOpenCloseDeleteDialog(false)}
        onDelete={onDeleteWorkflow}
      />
    </Stack>
  );
};

export default WorkflowComponent;
