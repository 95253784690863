import React from "react";
import { Box, Stack } from "@mui/material";
import DataGrid from "../../components/DataGrid_new";
import Loading from "../../components/Loading";
import Pagination from "../../components/Pagination";
import { IApplicationsComponent } from "../../models/Applications";
import {
  DATA_GRID_LIST_TYPE,
  IDataGrid,
  TDataGridActions,
} from "../../models/DataGrid";
import CardView from "./CardView";
import ApplicationsToolbar from "./ApplicationsToolbar";
import ApplicationsFilters from "./filters";
import ApplicationsNoResultOverlay from "./NoResultOverlay";
import { StyledApplicationsContainer } from "./styles";
import ApplyJobDialog from "../../components/dialogs/ApplyJobDialog";
import { DIALOG_TYPE } from "../../models/common";
import DeleteDialog from "../../components/dialogs/DeleteDialog";

const ApplicationsComponent = ({
  t,
  list,
  view,
  jobId,
  actions,
  selectedApplications,
  isListLoading,
  pageSize,
  pageNum,
  dialogType,
  applicationsCount,
  containerMargin = 4,
  showAddApplicationButton,
  showPremiumButton,
  statuses,
  initialColumns,
  onItemClick,
  onSelect,
  onPaginate,
  onOpenDialog,
  onCloseDialog,
  onDeleteApplication,
}: IApplicationsComponent) => (
  <StyledApplicationsContainer
    mt={containerMargin}
    data-testid={"applications-list-component-wrapper"}
  >
    <ApplicationsFilters
      t={t}
      jobId={jobId}
      view={view}
      onOpenApplyJobDialog={() => onOpenDialog(null, DIALOG_TYPE.APPLY_JOB)}
    />
    {view === "table" && (
      <ApplicationsToolbar
        t={t}
        selectedApplications={selectedApplications}
        onExport={actions.onExport}
        showAddApplicationButton={showAddApplicationButton}
        onOpenApplyJobDialog={() => onOpenDialog(null, DIALOG_TYPE.APPLY_JOB)}
      />
    )}
    {isListLoading ? (
      <Loading />
    ) : list?.length ? (
      <Box height={"100%"} data-testid={"applications-list-data-grid-wrapper"}>
        {view === "table" ? (
          <Stack height={"100%"}>
            <DataGrid
              t={t}
              list={list}
              type={
                jobId
                  ? DATA_GRID_LIST_TYPE.APPLICATION_LIST_SINGLE_JOB
                  : DATA_GRID_LIST_TYPE.APPLICATION_LIST
              }
              actions={actions as TDataGridActions}
              onSelect={onSelect as IDataGrid["onSelect"]}
            />
            <Pagination
              totalPosts={applicationsCount}
              itemsPerPage={pageSize}
              page={pageNum}
              paginate={onPaginate}
            />
          </Stack>
        ) : (
          <CardView
            t={t}
            variant="candidate"
            statuses={statuses}
            initialColumns={initialColumns}
            isApplicationView
            onItemClick={onItemClick}
          />
        )}
      </Box>
    ) : (
      <ApplicationsNoResultOverlay
        t={t}
        showPremiumButton={showPremiumButton}
      />
    )}
    <ApplyJobDialog
      isOpen={dialogType === DIALOG_TYPE.APPLY_JOB}
      onCancel={onCloseDialog}
    />
    <DeleteDialog
      t={t}
      isOpen={dialogType === DIALOG_TYPE.DELETE}
      title={t("applications.delete_application")}
      body={t("applications.delete_application_text")}
      note={t("applications.delete_application_note") as string}
      customDeleteButtonText={t("applications.delete_application") as string}
      onCancel={onCloseDialog}
      onDelete={onDeleteApplication}
    />
  </StyledApplicationsContainer>
);

export default ApplicationsComponent;
