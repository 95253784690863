import React from "react";
import { ClockIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import {
  IPremiumProductsComponent,
  TPremiumProductItem,
} from "../../../models/SingleJob";
import {
  StyledAddBtnContainer,
  StyledDaysBlock,
  StyledDiscountLabel,
  StyledDivider,
  StyledPremiumPackagesProductsContainer,
  StyledProductBodyItem,
  StyledProductContainerHeader,
  StyledProductsContainer,
  StyledVerticalDivider,
} from "../styles";
import ProductsFilter from "./filter";
import ProductsEmptyState from "./ProductsEmptyState";

const PremiumProductsComponent = ({
  t,
  products,
  isSelected,
  onAddProduct,
  onRemoveFromCart,
  onResetFilters,
}: IPremiumProductsComponent) => (
  <StyledPremiumPackagesProductsContainer>
    <Stack
      flex={1}
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Typography variant="body1">
        {t("create_job_fourth_step.products")}
      </Typography>
      <ProductsFilter t={t} />
    </Stack>
    <StyledDivider />
    {products?.length ? (
      <Stack direction={"row"} flexWrap={"wrap"} gap={3}>
        {products?.map((product) => (
          <StyledProductsContainer key={product.name}>
            <StyledProductContainerHeader>
              {product.products[0].image_url ? (
                <img src={product.products[0].image_url} alt={product.name} />
              ) : (
                <Typography
                  variant="subtitle2"
                  height={40}
                  display="flex"
                  alignItems="center"
                >
                  {product.name}
                </Typography>
              )}
            </StyledProductContainerHeader>
            <StyledDivider />
            <Stack spacing={1} mt={2}>
              {product.products?.map((productItem: TPremiumProductItem) => (
                <StyledProductBodyItem key={productItem.id}>
                  <Stack flex={1}>
                    <Stack direction={"row"} spacing={1}>
                      <Tooltip title={productItem.name}>
                        <Typography
                          variant="subtitle2"
                          noWrap
                          maxWidth={300}
                          overflow="hidden"
                          textOverflow="ellipsis"
                        >
                          {productItem.name}
                        </Typography>
                      </Tooltip>
                      {productItem.discount && productItem.discount > 0 ? (
                        <StyledDiscountLabel>
                          {productItem.discount} %
                        </StyledDiscountLabel>
                      ) : null}
                    </Stack>
                    <StyledDaysBlock mt={0.5}>
                      <ClockIcon />
                      <span className="product-days">
                        {productItem.runtime_in_days}{" "}
                        {t("create_job_fourth_step.days")}
                      </span>
                    </StyledDaysBlock>
                  </Stack>
                  <Stack direction={"column"} alignItems={"flex-end"} mt={0}>
                    {productItem.agency_price
                      ? productItem.agency_price <
                          productItem.recommended_retail_price && (
                          <Box className={"old-price"}>
                            {productItem.recommended_retail_price} €
                          </Box>
                        )
                      : productItem.retail_price <
                          productItem.recommended_retail_price && (
                          <Box className={"old-price"}>
                            {productItem.recommended_retail_price} €
                          </Box>
                        )}
                    <Box className={"new-price"}>
                      {productItem.agency_price || productItem.retail_price} €
                    </Box>
                  </Stack>
                  <StyledVerticalDivider orientation={"vertical"} />
                  <StyledAddBtnContainer>
                    {isSelected(productItem) ? (
                      <CheckCircleIcon
                        className={"success"}
                        onClick={() => onRemoveFromCart(productItem)}
                      />
                    ) : (
                      <PlusCircleIcon
                        onClick={() => onAddProduct(productItem)}
                      />
                    )}
                  </StyledAddBtnContainer>
                </StyledProductBodyItem>
              ))}
            </Stack>
          </StyledProductsContainer>
        ))}
      </Stack>
    ) : (
      <ProductsEmptyState t={t} onResetFilters={onResetFilters} />
    )}
  </StyledPremiumPackagesProductsContainer>
);

export default PremiumProductsComponent;
