import React from "react";
import { Stack } from "@mui/material";
import { ShoppingBagIcon } from "@heroicons/react/24/outline";
import Loading from "../../../components/Loading";
import PremiumPackagesComponent from "./Packages";
import PremiumProductsComponent from "./Products";
import DiscoverProducts from "./DisoverProducts";
import { IPremiumJobBoardsComponent } from "../../../models/SingleJob";
import { StyledPreviewButton } from "../../../components/JobDialogs_new/styles";
import {
  StyledPremiumJobBoardsContainer,
  StyledShoppingCartButtonContainer,
} from "../styles";

const PremiumJobBoardsComponent = ({
  t,
  isLoading,
  packages,
  products,
  displayAllPackages,
  selectedProducts,
  isCartIcon,
  isDiscoverPage,
  totalShopProducts,
  isSelected,
  onOpenProduct,
  onAddPackage,
  onAddProduct,
  onRemoveFromCart,
  onResetFilters,
  onDisplayAllPackages,
  onOpenCart,
}: IPremiumJobBoardsComponent) =>
  isLoading ? (
    <Loading />
  ) : (
    <StyledPremiumJobBoardsContainer>
      {packages?.length ? (
        <PremiumPackagesComponent
          t={t}
          isDiscoverPage={isDiscoverPage}
          packages={packages}
          displayAllPackages={displayAllPackages}
          isSelected={isSelected}
          onAddPackage={onAddPackage}
          onOpenProduct={onOpenProduct}
          onRemoveFromCart={onRemoveFromCart}
          onDisplayAllPackages={onDisplayAllPackages}
        />
      ) : null}
      {isDiscoverPage ? (
        <DiscoverProducts
          t={t}
          products={products}
          totalShopProducts={totalShopProducts}
          isSelected={isSelected}
          onAddProduct={onAddProduct}
          onRemoveFromCart={onRemoveFromCart}
          onResetFilters={onResetFilters}
          onOpenProduct={onOpenProduct}
        />
      ) : (
        <PremiumProductsComponent
          t={t}
          products={products}
          isSelected={isSelected}
          onAddProduct={onAddProduct}
          onRemoveFromCart={onRemoveFromCart}
          onResetFilters={onResetFilters}
          onOpenProduct={onOpenProduct}
        />
      )}
      {isCartIcon && (
        <Stack width="100%" alignItems="flex-end" position="relative" top={0}>
          <StyledShoppingCartButtonContainer onClick={onOpenCart}>
            <StyledPreviewButton disabled={isLoading}>
              <ShoppingBagIcon width={24} height={24} />
            </StyledPreviewButton>
            <Stack className={"count-label"}>{selectedProducts.length}</Stack>
          </StyledShoppingCartButtonContainer>
        </Stack>
      )}
    </StyledPremiumJobBoardsContainer>
  );

export default PremiumJobBoardsComponent;
