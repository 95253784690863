import React from "react";
import { Stack, Typography } from "@mui/material";
import { TFunction } from "i18next";
import { IJobOrder } from "../../../models/SingleJob";
import StatusList from "../../../components/elements/StatusList";
import { shortMonthDateFormat } from "../../../utils";

const OrderItemDetails = ({
  t,
  order
}: {
  readonly t: TFunction;
  readonly order: IJobOrder;
}) => {
  const expiredOrder = order.products.every((item) => item.remainingDays === 0);
  return (
    <Stack
      width="100%"
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography variant="subtitle2" fontWeight={500}>
        Order ID #{order.orderId} {t("basic_job_board.published_at")}{" "}
        {shortMonthDateFormat(order.publishedDate)}
      </Typography>
      <StatusList
        candidate={{
          status: expiredOrder ? t("expired") : order.status.replace(" ", "_")
        }}
        noDropdown
      />
    </Stack>
  );
};

export default OrderItemDetails;
