import React from "react";
import { Divider } from "@mui/material";
import { useSelector } from "react-redux";
import CompanyHeader from "./CompanyHeader";
import Carousel from "./Carousel";
import JobDescription from "./JobDescription";
import OtherJobs from "./OtherJobs";
import { StyledJobPreviewTemplateContainer } from "../styles";
import { formatLogoPrefix, formatLogoUrl } from "../../../utils";
import { getJobPreviewState } from "../../../store_new/selectors/JobPreview";
import { getCurrentUserData } from "../../../store_new/selectors/CurrentUser";

const JobTemplate = ({
  desktop,
  companyColor,
}: {
  readonly desktop: boolean;
  readonly companyColor: string;
}) => {
  const { jobTemplate, totalJobs } = useSelector(getJobPreviewState);
  const mainLogo = jobTemplate?.logo;
  const { isAgency } = useSelector(getCurrentUserData);
  const logoPrefix = formatLogoPrefix();
  const formattedLogo = isAgency
    ? null
    : mainLogo
    ? formatLogoUrl(mainLogo)
    : null;
  const agencyCustomerName = localStorage.getItem("company-name");
  const customerName = isAgency
    ? agencyCustomerName || ""
    : jobTemplate?.company;

  return (
    <StyledJobPreviewTemplateContainer desktop={desktop} spacing={3}>
      <CompanyHeader
        jobTemplate={jobTemplate}
        desktop={desktop}
        companyColor={companyColor}
        logo={formattedLogo}
        customerName={customerName}
        isAgency={isAgency}
      />
      {desktop && <Carousel logoPrefix={logoPrefix} />}
      <JobDescription desktop={desktop} companyColor={companyColor} />
      {totalJobs > 1 && <Divider sx={{ borderColor: "#E5E7EB" }} />}
      {desktop && (
        <OtherJobs
          desktop={desktop}
          companyColor={companyColor}
          logo={formattedLogo}
          totalJobs={totalJobs}
        />
      )}
    </StyledJobPreviewTemplateContainer>
  );
};

export default JobTemplate;
