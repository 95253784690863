import React from "react";
import { Checkbox } from "@mui/material";
import {
  CellClickedEvent,
  ColDef,
  ICellRendererParams,
  ValueGetterParams,
} from "ag-grid-community";
import ActionsCell from "../cellRenderers/actions";
import StatusCell from "../cellRenderers/status";
import TeamMembers from "../cellRenderers/members";
import ApplicantsNumber from "../cellRenderers/applicationsNumber";
import {
  DATA_GRID_LIST_TYPE,
  TDataGridGetColDef,
} from "../../../models/DataGrid";

const getJobsColDef = ({
  t,
  actions,
  onSelect,
  type,
}: Pick<
  TDataGridGetColDef,
  "t" | "actions" | "onSelect" | "type"
>): ColDef[] => [
  {
    field: "",
    width: 76,
    cellClass: "ag-grid-cell-checkbox",
    cellRenderer: (params: ICellRendererParams) => (
      <Checkbox
        onClick={() => onSelect && onSelect(params.data)}
        color="secondary"
      />
    ),
    hide:
      type === DATA_GRID_LIST_TYPE.JOB_LIST_ARCHIVED ||
      type === DATA_GRID_LIST_TYPE.JOB_LIST_DRAFT,
  },
  {
    field: "title",
    headerName: t("joblist.title") as string,
    flex: 0.7,
    cellClass:
      type === DATA_GRID_LIST_TYPE.JOB_LIST_DRAFT
        ? "ag-grid-cell"
        : "ag-grid-cell-clickable",
    onCellClicked: (params: CellClickedEvent) => {
      if (type !== DATA_GRID_LIST_TYPE.JOB_LIST_DRAFT) {
        actions.onClick && actions.onClick(params.data);
      }
    },
  },
  {
    field: "locations",
    headerName: t("joblist.location_placeholder") as string,
    flex: 0.5,
    maxWidth: 200,
    valueGetter: (params: ValueGetterParams) =>
      params.data["locations"][0]?.city,
  },
  {
    field: "team",
    headerName: "Team",
    flex: 0.6,
    maxWidth: 200,
    hide: type === DATA_GRID_LIST_TYPE.JOB_LIST_DRAFT,
    cellRenderer: (params: ValueGetterParams) => (
      <TeamMembers owners={params.data.owners} />
    ),
  },
  {
    field: "contract_type",
    headerName: t("create_job_second_step.job_type") as string,
    flex: 0.5,
    maxWidth: 120,
  },
  {
    field: "seniority_level",
    headerName: t("joblist.seniority") as string,
    flex: 0.45,
    maxWidth: 120,
  },
  {
    field: "applicationsCount",
    headerName: t("navigation.applications") as string,
    flex: 0.55,
    maxWidth: 120,
    cellClass: "ag-grid-cell-clickable",
    hide: type === DATA_GRID_LIST_TYPE.JOB_LIST_DRAFT,
    onCellClicked: (params: CellClickedEvent) => {
      actions.onClick && actions.onClick(params.data, "applications");
    },
    cellRenderer: (params: ValueGetterParams) => (
      <ApplicantsNumber applicantsNumber={params.data.applicationsCount} />
    ),
  },
  {
    field: "status",
    flex: 0.5,
    maxWidth: 90,
    cellRenderer: (params: ICellRendererParams) =>
      StatusCell({
        label: params.value,
        type: params.value,
      }),
  },
  {
    field: "continueEditing",
    headerName: "",
    flex: 0.5,
    maxWidth: 240,
    cellRenderer: (params: ICellRendererParams) =>
      ActionsCell({
        t,
        actions: {
          onContinueEditing: () =>
            actions.onContinueEditing && actions.onContinueEditing(params.data),
        },
      }),
    hide: type !== DATA_GRID_LIST_TYPE.JOB_LIST_DRAFT,
  },
  {
    field: "promoteAction",
    headerName: "",
    flex: 0.6,
    maxWidth: 120,
    hide: type === DATA_GRID_LIST_TYPE.JOB_LIST_DRAFT,
    cellRenderer: (params: ICellRendererParams) =>
      ActionsCell({
        t,
        actions: {
          onPromote:
            type === DATA_GRID_LIST_TYPE.JOB_LIST_ACTIVE
              ? () => actions.onPromote && actions.onPromote(params.data)
              : undefined,
          onArchiveRestore:
            type === DATA_GRID_LIST_TYPE.JOB_LIST_ARCHIVED
              ? () => actions.onArchiveRestore!(params.data)
              : undefined,
        },
      }),
  },
  {
    field: "actions",
    headerName: "",
    sortable: false,
    flex: 0.3,
    maxWidth: 70,
    cellStyle: { justifyContent: "center" },
    cellRenderer: (params: ICellRendererParams) =>
      ActionsCell({
        t,
        actions: {
          onDuplicate: () =>
            actions.onDuplicate && actions.onDuplicate(params.data),
          ...(actions.onDelete && {
            onDelete: () => actions.onDelete && actions.onDelete(params.data),
          }),
          onInvite: () => actions.onInvite && actions.onInvite(params.data),
          onEdit: () => actions.onEdit && actions.onEdit(params.data),
          onPreview: () =>
            actions.onPreview && actions.onPreview(params.data.url_key),
          onArchiveRestore: () => actions.onArchiveRestore!(params.data),
          onExport: () => actions.onExport && actions.onExport(params.data),
          onSetWorkflow: () =>
            actions.onSetWorkflow && actions.onSetWorkflow(params.data),
        },
        type: params.data.status,
      }),
  },
];

export default getJobsColDef;
